/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Status = ({
  showDot = false,
  label = "label",
  style = "",
  color,
  emphasis,
  className,
}) => {
  return (
    <div className={`status ${emphasis} ${color} ${className}`} style={{style}} >
      {showDot && <div className="dot" />}

      <div className="label">{label}</div>
    </div>
  );
};

Status.propTypes = {
  showDot: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.oneOf(["warning", "neutral", "negative", "positive", "informative", "brand"]),
  emphasis: PropTypes.oneOf(["strong", "low", "medium"]),
};
