export const LegalStatusOptions = [
    { label: 'EI(RL)', value: 'EI(RL)' },
    { label: 'EURL', value: 'EURL' },
    { label: 'SASU', value: 'SASU' },
    { label: 'SA', value: 'SA' },
    { label: 'SARL', value: 'SARL' },
    { label: 'SAS', value: 'SAS' },
    { label: 'SCA', value: 'SCA' },
    { label: 'SCS', value: 'SCS' },
    { label: 'SNC', value: 'SNC' },
  ];
  
 export const CategoryOptions = [
    { label: 'Microentreprises (MIC)', value: 'Microentreprises (MIC)' },
    { label: 'Petites et moyennes entreprises (PME)', value: 'Petites et moyennes entreprises (PME)' },
    { label: 'Entreprises de taille intermédiaire (ETI)', value: 'Entreprises de taille intermédiaire (ETI)' },
    { label: 'Grandes entreprises (GE)', value: 'Grandes entreprises (GE)' },
  ];