// CreateRfpForm.js
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { CardSkills } from './CardSkills';
import { SkillLevelOptions } from '../utils/StatusSkills';
import { Select } from './Select';
import { Input } from '../components/Input';
import { Button } from "../components/Button";
import { mdiCalendar } from '@mdi/js';
import { arraysEqual } from '../utils/dataTools';
import { mdiAccountOutline, mdiPlus, mdiWeb, mdiFileCogOutline, mdiBriefcaseCheckOutline, mdiClose } from '@mdi/js';
import { countWorkdays } from '../utils/dateUtils';
import { OfferTypeOptions, selectRemoteOptions } from '../utils/selectOptionsUtils';
import { IconButton } from '../components/IconButton';

const CreateRfpForm = ({ closeDrawer }) => {
  const [formData, setFormData] = useState({
    id: '',
    id_client: '',
    status: 0,
    client_ref: '',
    client_mkt: '',
    eq_ref: '',
    est_amount: '',
    owner: '',
    rfp_title: '',
    max_proposals: 0,
    offer_type: '',
    price_model: '',
    amount: 0,
    rfp_last_date: '',
    rfp_start_date: '',
    rfp_end_date: '',
    rfp_opt_start_date: '',
    rfp_opt_end_date: '',
    renewable: false,
    location_primary: '',
    location_secondary: '',
    remote: '',
    line_of_business: '',
    summ_desc: '',
    full_desc: '',
    deliverables: '',
    seniority: '',
    level: '',
    skills: [],
    languages: [],
    diplomas: []
  });
  const [clients, setClients] = useState([]);
  const [clientsOptions, setClientsOptions] = useState([]);
  const navigate = useNavigate();
  const [selectedClient, setSelectedClient] = useState('');
  const [marketOptions, setMarketOptions] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false); // Track when to submit


  const auth = useAuth();
  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await api.get('/client');
        setClients(response.data.items);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    fetchClients();
    const fetchCounterValue = async () => {
      try {
        const response = await api.get('/rfp/next-counter');  // Assume this endpoint returns the next counter value
        const counterValue = response.data.counter;

        // Generate the eq_ref string based on the counter value and date
        const date = new Date();
        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const eq_ref = `${year}${month}${counterValue.toString().padStart(4, '0')}`;

        setFormData(prevFormData => ({
          ...prevFormData,
          eq_ref
        }));
      } catch (error) {
        console.error('Error fetching counter value:', error);
      }
    };

    fetchCounterValue();
  }, []);

  useEffect(() => {
    const selectClientOptions = () => {
      const options = clients.map(client => ({
        label: client.name,
        value: client.id,
        markets: client.markets
      }));
      setClientsOptions(options);
    };

    if (clients.length > 0) {
      selectClientOptions();
    }
  }, [clients]); // Only re-run this effect if `clients` changes

  const [diplomasData, setDiplomasData] = useState([]);
  const handleSaveDiplomas = (updatedDiplomas) => {
    if (!arraysEqual(diplomasData, updatedDiplomas)) {
      setDiplomasData(updatedDiplomas);
      setFormData(prevFormData => ({
        ...prevFormData,
        diplomas: updatedDiplomas
      }));
      console.log('Diplomas updated');
    } else {
      //console.log('No update needed', formData.languages);
    }
  };

  const [skillsData, setSkillsData] = useState([]);
  const handleSaveskills = useCallback((updatedSkills) => {
    if (!arraysEqual(skillsData, updatedSkills)) {
      setSkillsData(updatedSkills);
      setFormData(prevFormData => ({
        ...prevFormData,
        skills: updatedSkills
      }));
      console.log('Skills updated');
    } else {
      //console.log('No update needed', formData.skills);
    }
  }, [skillsData, setSkillsData, setFormData]);

  const [languagesData, setLanguagesData] = useState([]);
  const handleSaveLanguages = (updatedLanguages) => {
    if (!arraysEqual(languagesData, updatedLanguages)) {
      setLanguagesData(updatedLanguages);
      setFormData(prevFormData => ({
        ...prevFormData,
        languages: updatedLanguages
      }));
      console.log('Languages updated');
    } else {
      //console.log('No update needed', formData.languages);
    }
  };

  // Handle change
  const [invalidFields, setInvalidFields] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newInvalidFields = { ...invalidFields };
  
    // Check if value is a string and trim it, otherwise check its length
    if ((typeof value === 'string' && value.trim() === '') || 
        (typeof value !== 'string' && value.length === 0)) {
      newInvalidFields[name] = true; // mark as invalid if empty
    } else {
      delete newInvalidFields[name]; // remove from invalid fields if it's now valid
    }
  
    setInvalidFields(newInvalidFields);
    setFormData({ ...formData, [name]: value });
  };

  const handleOfferTypeChange = useCallback((newValue) => {
    const newInvalidFields = { ...invalidFields };

    if (newValue === '') {
      newInvalidFields.offer_type = true; // mark as invalid if empty
    } else {
      delete newInvalidFields.offer_type; // remove from invalid fields if it's now valid
    }

    setInvalidFields(newInvalidFields);
    setFormData({ ...formData, offer_type: newValue });
  }, [formData, setFormData, invalidFields]);

  const handleClientChange = (selectedOption) => {
    console.log('Selected Client Option:', selectedOption); // Log the selected option to verify

    setSelectedClient(selectedOption.value);
    setFormData({ ...formData, id_client: selectedOption.value, client_mkt: '' }); // Update id_client with selected client ID
    setSelectedMarket({}); // Reset selected market
    setMarketOptions([]);
    const selectedClient = clientsOptions.find(client => client.value === selectedOption.value);
    console.log('Selected Client:', selectedClient); // Log the selected client to verify
    if (selectedClient && selectedClient.markets && selectedClient.markets.length > 0) {
      setMarketOptions(selectedClient.markets.map(market => ({ label: market.title, value: market.title })));
    } else {
      setMarketOptions([]);
    }

    const newInvalidFields = { ...invalidFields };

    if (!selectedOption.value) {
      newInvalidFields.id_client = true; // mark as invalid if empty
    } else {
      delete newInvalidFields.id_client; // remove from invalid fields if it's now valid
    }

    setInvalidFields(newInvalidFields);
  };

  const handleMarketChange = (selectedOption) => {
    setSelectedMarket(selectedOption.value);
    setFormData({ ...formData, client_mkt: selectedOption.value });
  };

  const handleRemoteOptionChange = (newValue) => {
    setFormData({ ...formData, remote: newValue });
  };

  const validateFields = useCallback(() => {
    const requiredFields = [
      'client_ref',
      'offer_type',
      'id_client',
      'est_amount',
      'rfp_title',
      'rfp_last_date',
      'rfp_start_date',
      'rfp_end_date',
      'location_primary',
    ];

    const newInvalidFields = {};
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newInvalidFields[field] = true;
      }
    });
    setInvalidFields(newInvalidFields);
    return Object.keys(newInvalidFields).length === 0;
  }, [formData]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    // Validate the required fields
    if (!validateFields()) {
      console.error("Invalid input, cannot submit.");
      setIsSubmitting(false);
      return;
    }
  
    // Create a copy of formData and conditionally remove the optional date fields if they are empty
    const filteredFormData = { ...formData };
  
    if (!filteredFormData.rfp_opt_start_date) {
      delete filteredFormData.rfp_opt_start_date;
    }
  
    if (!filteredFormData.rfp_opt_end_date) {
      delete filteredFormData.rfp_opt_end_date;
    }
  
    try {
      console.log('Submitting form:', filteredFormData);
      await api.post('/rfp', filteredFormData);
      closeDrawer(); // Close the Drawer after successful submission
      navigate('/');
    } catch (error) {
      console.error('Error creating RFP:', error);
    } finally {
      setIsSubmitting(false);
  }
  }, [formData, closeDrawer, isSubmitting, navigate, validateFields]);
  
  // Watch for status update and trigger handleSubmit
  useEffect(() => {
    if (formData.status === 2 && shouldSubmit) {
      // Create a synthetic event object
      const syntheticEvent = {
        preventDefault: () => {} // No-op function
      };

      handleSubmit(syntheticEvent);
      setShouldSubmit(false); // Reset after submission
    }
  }, [formData.status, shouldSubmit, handleSubmit]);

  const broadcast = async () => {
    // Set status to 2 and trigger handleSubmit
    setFormData(prevFormData => ({ ...prevFormData, status: 2 }));
    setShouldSubmit(true); // Indicate that submit should occur
  };

  const wrapSubmit = async () => {
    setShouldSubmit(true); // Indicate that submit should occur
  }
  return (
    <div className='drawer-container'>
      <form onSubmit={handleSubmit}>
        <div className="header drawer-title">
          <div className="header rfptitle">Nouvel appel d'offre</div>
          <div className="header button-jsti-fix" >
            <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label="Enregistrer le brouillon"
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
              onClick={wrapSubmit}
              width='140px'
            />
            <Button
              appearance="primary "
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label="Diffuser"
              size="small"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
              onClick={broadcast}
              width='140px'
            />
            <IconButton
              appearance="primary"
              hasDropdownIndicator={false}
              rounded={false}
              size="small"
              stateProp="normal"
              type="ghost"
              customIcon={mdiClose}
              onClick={closeDrawer} // Close the Drawer on button click
            />
          </div>
        </div>
        <div className='drawer-rfp-form-bloc'>
          <div className='header-xsmall'>
            Informations
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Référence Equity
              </div>
              <div className='input-content-normal'>
                <Input type="text" name="eq_ref" customWidth="100%" value={formData.eq_ref} disabled />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Référence Interne client
              </div>
              <div className='input-content-normal'>
                <Input type="text"
                  name="client_ref"
                  value={formData.client_ref}
                  onChange={handleInputChange}
                  isInvalid={invalidFields.client_ref}
                  required
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Typologie de prestation
              </div>
              <div className='input-content-normal'>
                <Select
                  name="offer_type"
                  value={formData.offer_type}
                  options={OfferTypeOptions}
                  onChange={handleOfferTypeChange}
                  isInvalid={invalidFields.offer_type}
                  required
                />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Nom du client
              </div>
              <div className='input-content-normal'>
                <Select
                  name="client_name"
                  options={clientsOptions}
                  value={selectedClient}
                  onChange={handleClientChange}
                  version='v2'
                  isInvalid={invalidFields.id_client}
                  required
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Nom du marché (facultatif)
              </div>
              <div className='input-content-normal'>
                <Select
                  name="client_mkt"
                  options={marketOptions}
                  value={selectedMarket}
                  onChange={handleMarketChange}
                  version='v2'

                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Montant estimé
              </div>
              <div className='input-content-normal'>
                <Input
                  name="est_amount"
                  value={formData.est_amount}
                  onChange={handleInputChange}
                  suffix="€ HT"
                  isInvalid={invalidFields.est_amount}
                  required
                />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Nom de l'appel d'offre
              </div>
              <div className='input-content-normal'>
                <Input
                  type="text"
                  name="rfp_title"
                  value={formData.rfp_title}
                  onChange={handleInputChange}
                  isInvalid={invalidFields.rfp_title}
                  required
                />
              </div>
            </div>

          </div>
        </div>
        <div className='drawer-rfp-form-bloc'>
          <div className='header-xsmall'>
            Dates et période
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Date limite de reponse
              </div>
              <div className='input-content-normal'>
                <Input
                  type="date"
                  name="rfp_last_date"
                  onChange={handleInputChange}
                  placeholder="jj/mm/aaaa"
                  iconAfter={mdiCalendar}
                  value={formData.rfp_last_date}
                  isInvalid={invalidFields.rfp_last_date}
                  openToCurrentDate={true}
                  required
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Date début de mission
              </div>
              <div className='input-content-normal'>
                <Input
                  type="date"
                  name="rfp_start_date"
                  onChange={handleInputChange}
                  placeholder="jj/mm/aaaa"
                  iconAfter={mdiCalendar}
                  value={formData.rfp_start_date}
                  isInvalid={invalidFields.rfp_start_date}
                  openToCurrentDate={true}
                  required
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Date fin de mission
              </div>
              <div className='input-content-normal'>
                <Input
                  type="date"
                  name="rfp_end_date"
                  onChange={handleInputChange}
                  placeholder="jj/mm/aaaa"
                  iconAfter={mdiCalendar}
                  value={formData.rfp_end_date}
                  isInvalid={invalidFields.rfp_end_date}
                  openToCurrentDate={true}
                  required
                />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Période optionnelle - Début
              </div>
              <div className='input-content-normal'>
                <Input
                  type="date"
                  name="rfp_opt_start_date"
                  placeholder="jj/mm/aaaa"
                  onChange={handleInputChange}
                  iconAfter={mdiCalendar}
                  openToCurrentDate={true}
                  value={formData.rfp_opt_start_date}
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Période optionnelle - Fin
              </div>
              <div className='input-content-normal'>
                <Input
                  type="date"
                  name="rfp_opt_end_date"
                  placeholder="jj/mm/aaaa"
                  onChange={handleInputChange}
                  iconAfter={mdiCalendar}
                  openToCurrentDate={true}
                  value={formData.rfp_opt_end_date}
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Durée de la mission
              </div>
              <div className='input-content-normal'>
                <Input
                  type="text"
                  name="rfp_duration"
                  value={countWorkdays(formData.rfp_start_date, formData.rfp_end_date)}
                  onChange={handleInputChange}
                  suffix="Jours"
                  disabled />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Mission renouvelable
              </div>
              <div className='input-content-normal'>

              </div>
            </div>
          </div>
        </div>
        <div className='drawer-rfp-form-bloc'>
          <div className='header-xsmall'>
            Lieux de mission
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Lieu de mission 1
              </div>
              <div className='input-content-normal'>
                <Input
                  type="text"
                  name="location_primary"
                  value={formData.location_primary}
                  onChange={handleInputChange}
                  isInvalid={invalidFields.location_primary}
                  required
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Lieu de mission 2 (facultatif)
              </div>
              <div className='input-content-normal'>
                <Input
                  type="text"
                  name="location_secondary"
                  value={formData.location_secondary}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Télétravail
              </div>
              <div className='input-content-normal'>
                <Select name="remote" options={selectRemoteOptions} value={formData.remote} onChange={handleRemoteOptionChange} />
              </div>
            </div>
          </div>
        </div>
        <div className='drawer-rfp-form-bloc'>
          <div className='header-xsmall'>
            Détail de la mission
          </div>

          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                Description de la mission
              </div>
              <div className={`input-content-normal `}>
                <textarea className={`textareaSK ${invalidFields.summ_desc ? 'input-error' : ''}`} name="summ_desc" value={formData.summ_desc} onChange={handleInputChange} required
                  rows={4}
                  cols={60}>

                </textarea>
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className='input-container-normal'>
              <div className='input-header-normal'>
                  Livrables
              </div>
              <div className={`input-content-normal `}>
                <textarea className={`textareaSK ${invalidFields.full_desc ? 'input-error' : ''}`} name="full_desc" value={formData.full_desc} onChange={handleInputChange} required
                  rows={8}
                  cols={60}
                ></textarea>
              </div>

            </div>
          </div>
        </div>
        <div>
          <CardSkills
            skills={diplomasData}
            iconPath={mdiBriefcaseCheckOutline}
            saveLanguages={handleSaveDiplomas}
            title="Diplômes et certifications demandés"
            placeHolder="Certification ou diplôme"
            editButton={false}
            lCollapsed={false}
            context='form'
            SkillLevelOptions={SkillLevelOptions}
            hasLevelSelect={true}
          />
        </div>
        <div>
          <CardSkills
            skills={skillsData}
            iconPath={mdiFileCogOutline}
            saveLanguages={handleSaveskills}
            title="Compétences"
            placeHolder="Compétence"
            editButton={false}
            lCollapsed={false}
            context='form'
            SkillLevelOptions={SkillLevelOptions}
            hasLevelSelect={true}
          />
        </div>
        <div>
          <CardSkills
            skills={languagesData}
            iconPath={mdiWeb}
            saveLanguages={handleSaveLanguages}
            title="Langues"
            placeHolder="Langue"
            editButton={false}
            lCollapsed={false}
            context='form'
            SkillLevelOptions={SkillLevelOptions}
            hasLevelSelect={true}
          />
        </div>
      </form>
    </div>
  );
}

export default CreateRfpForm;
