import React, { useState, useEffect } from "react";
import { mdiAccountOutline } from '@mdi/js';
import { Input } from "../../components/Input";
import CardHeader from '../CardHeader';
import { CategoryOptions, LegalStatusOptions} from '../../utils/corpUtils'
import { Select } from '../Select';
import "./style.css";

export const CardPartnerInfo = ({
  info = {},  // Default empty object to hold all relevant info properties
  handleSubmit,
  onInfoChange,
  title,
  editButton = true,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [_info, setInfo] = useState(info || {});

  useEffect(() => {
    setInfo(info || {});  // Ensure address is always an object
  }, [info]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newInfo = { ..._info, [name]: value };

    setInfo(newInfo);
    if (onInfoChange) {
      onInfoChange(newInfo);  // Notify parent of changes
    }
  };
  const handleLegalStatusChange = (newValue) => {
    const updatedInfo = { ..._info, legal_status: newValue };
    setInfo(updatedInfo);
    if (onInfoChange) {
      onInfoChange(updatedInfo);  // Notify parent of changes
    }
  };

  const handleCategoryChange = (newValue) => {
    const updatedInfo = { ..._info, category: newValue };
    setInfo(updatedInfo);
    if (onInfoChange) {
      onInfoChange(updatedInfo);  // Notify parent of changes
    }
  };
  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();  // Trigger handleSubmit from parent
    }
    setIsEditing(!isEditing);
  };

  const handleDiscard = () => {
    setInfo(info || {});  // Reset to original info, ensuring it's not null
    if (onInfoChange) {
      onInfoChange(info);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="card-component">
      <CardHeader
        isEditing={!isEditing}
        context='editor'
        editButton={editButton}
        iconPath={mdiAccountOutline}
        title={title}
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
      <div className="card-content v-padding-s">
      <div className="inputs-row">
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Raison sociale :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Input
          type="text"
          name="name"
          value={_info.name || ''}
          onChange={handleInputChange}
        />
      ) : _info.name}
    </div>
  </div>
</div>
<div className="inputs-row">
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Numéro de TVA :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Input
          type="text"
          name="vat_nbr"
          value={_info.vat_nbr || ''}
          onChange={handleInputChange}
        />
      ) : _info.vat_nbr}
    </div>
  </div>
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Numéro de Siren :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Input
          type="text"
          name="siren"
          value={_info.siren || ''}
          onChange={handleInputChange}
        />
      ) : _info.siren}
    </div>
  </div>
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Ville d’immatriculation RCS :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Input
          type="text"
          name="rcs"
          value={_info.rcs || ''}
          onChange={handleInputChange}
        />
      ) : _info.rcs}
    </div>
  </div>
</div>
<div className="inputs-row">
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Forme juridique :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Select name="legal_status" value={_info.legal_status} options={LegalStatusOptions} onChange={handleLegalStatusChange} />
      ) : _info.legal_status}
    </div>
  </div>
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Catégorie d’entreprise :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Select name="category" value={_info.category} options={CategoryOptions} onChange={handleCategoryChange} />
      ) : _info.category}
    </div>
  </div>
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Capital :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Input
          type="text"
          name="capital"
          className='input-normal'
          value={_info.capital || ''}
          onChange={handleInputChange}
        />
      ) : _info.capital}
    </div>
  </div>
</div>
<div className="inputs-row">
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Téléphone (facultatif) :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Input
          type="text"
          name="phone_number"
          className='input-normal'
          value={_info.phone_number || ''}
          onChange={handleInputChange}
        />
      ) : _info.phone_number}
    </div>
  </div>
  <div className="input-container-normal">
    <div className='input-header-normal'>
      Site Internet (facultatif) :
    </div>
    <div className='drawer-formfield-inactive'>
      {isEditing ? (
        <Input
          type="text"
          name="web_site"
          className='input-normal'
          value={_info.web_site || ''}
          onChange={handleInputChange}
        />
      ) : _info.web_site}
    </div>
  </div>
</div>

      </div>
    </div>
  );
};
