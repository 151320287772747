import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { mdiChevronDown } from '@mdi/js';
import Icon from '@mdi/react';
import "./style.css";

export const Button = ({
  label = "label",
  type = "default",
  appearance = "primary",
  intent = "none",
  size = "large",
  hasBeforeIcon = false,
  hasAfterIcon = false,
  hasDropdownIndicator = false,
  stateProp = "normal",
  className,
  customBeforeIcon,
  customAfterIcon,
  onClick,
  width = '100%',
}) => {
  const [buttonState, setButtonState] = useState({
    type,
    appearance,
    intent,
    size,
    hasBeforeIcon,
    hasAfterIcon,
    hasDropdownIndicator,
    state: stateProp,
  });

  // Effect to update state when props change
  useEffect(() => {
    setButtonState({
      type,
      appearance,
      intent,
      size,
      hasBeforeIcon,
      hasAfterIcon,
      hasDropdownIndicator,
      state: stateProp
    });
  }, [type, appearance, intent, size, hasBeforeIcon, hasAfterIcon, hasDropdownIndicator, stateProp]);

  const handleMouseEnter = () => {
    if (buttonState.state !== 'disabled') {
      setButtonState(prevState => ({ ...prevState, state: 'hover' }));
    }
  };
  
  const handleMouseLeave = () => {
    if (buttonState.state !== 'disabled') {
      setButtonState(prevState => ({ ...prevState, state: stateProp }));
    }
  };

  const handleClick = (event) => {
    if (buttonState.state === 'disabled') {
      event.preventDefault();
      return;
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      className={`button ${buttonState.state} has-before-icon-${buttonState.hasBeforeIcon} has-dropdown-indicator-${buttonState.hasDropdownIndicator} ${buttonState.type} ${buttonState.size} ${buttonState.appearance} ${buttonState.intent} has-after-icon-${buttonState.hasAfterIcon} ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      style={{ width: width }}
    >
      {buttonState.hasBeforeIcon && (
        <Icon
          path={customBeforeIcon}
          className={`${
            (buttonState.size === "large" ||
              buttonState.size === "medium-default" ||
              (buttonState.size === "small" && buttonState.type === "default") ||
              (buttonState.size === "small" && buttonState.type === "ghost")) &&
            "class"
          } ${buttonState.size === "small" && buttonState.type === "bare" && "class-2"}`}
          color={
            buttonState.appearance === "primary" && buttonState.type === "default"
              ? "white"
              : (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "disabled") ||
                (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "normal") ||
                (buttonState.appearance === "tertiary" && buttonState.state === "disabled") ||
                (buttonState.appearance === "tertiary" && buttonState.state === "normal")
              ? "#3F587E"
              : (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "disabled" &&
                  buttonState.type === "ghost") ||
                (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "normal" &&
                  buttonState.type === "ghost")
              ? "#00A0AF"
              : (buttonState.appearance === "secondary" && buttonState.intent === "positive" && buttonState.state === "disabled") ||
                (buttonState.appearance === "secondary" && buttonState.intent === "positive" && buttonState.state === "normal") ||
                (buttonState.intent === "positive" && buttonState.state === "disabled" && buttonState.type === "bare") ||
                (buttonState.intent === "positive" && buttonState.state === "disabled" && buttonState.type === "ghost") ||
                (buttonState.intent === "positive" && buttonState.state === "normal" && buttonState.type === "bare") ||
                (buttonState.intent === "positive" && buttonState.state === "normal" && buttonState.type === "ghost")
              ? "#00CCA7"
              : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "disabled") ||
                (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "normal") ||
                (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "ghost") ||
                (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "ghost")
              ? "#FF4C65"
              : buttonState.state === "hover" &&
                ["secondary", "tertiary"].includes(buttonState.appearance) &&
                (buttonState.appearance === "tertiary" || buttonState.intent === "none")
              ? "#1D283A"
              : buttonState.appearance === "primary" &&
                buttonState.intent === "none" &&
                buttonState.state === "hover" &&
                buttonState.type === "ghost"
              ? "#00707B"
              : (buttonState.appearance === "secondary" && buttonState.intent === "positive" && buttonState.state === "hover") ||
                (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "bare") ||
                (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "ghost")
              ? "#00AB8C"
              : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "hover") ||
                (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "ghost")
              ? "#D64055"
              : (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "disabled" &&
                  buttonState.type === "bare") ||
                (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "normal" &&
                  buttonState.type === "bare") ||
                (buttonState.state === "disabled" && buttonState.type === "link") ||
                (buttonState.state === "normal" && buttonState.type === "link")
              ? "#2DB7E3"
              : buttonState.state === "hover" &&
                (buttonState.appearance === "primary" || buttonState.type === "link") &&
                (buttonState.intent === "none" || buttonState.type === "link") &&
                ["bare", "link"].includes(buttonState.type)
              ? "#269ABF"
              : undefined
          }
        />
      )}

      {!buttonState.hasAfterIcon && buttonState.hasBeforeIcon && (
        <div className="button-container">
          {buttonState.hasDropdownIndicator && (
            <>
              <div className="button-label">{label}</div>
              <Icon
                path={mdiChevronDown}
                className={`${buttonState.size === "small" && buttonState.type === "bare" ? "class-2" : "class"}`}
                color={
                  buttonState.appearance === "primary" && buttonState.type === "default"
                    ? "white"
                    : (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "disabled") ||
                      (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "normal") ||
                      (buttonState.appearance === "tertiary" && buttonState.state === "disabled") ||
                      (buttonState.appearance === "tertiary" && buttonState.state === "normal")
                    ? "#3F587E"
                    : (buttonState.appearance === "primary" &&
                        buttonState.intent === "none" &&
                        buttonState.state === "disabled" &&
                        buttonState.type === "ghost") ||
                      (buttonState.appearance === "primary" &&
                        buttonState.intent === "none" &&
                        buttonState.state === "normal" &&
                        buttonState.type === "ghost")
                    ? "#00A0AF"
                    : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "disabled") ||
                      (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "normal") ||
                      (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "bare") ||
                      (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "ghost") ||
                      (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "bare") ||
                      (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "ghost")
                    ? "#FF4C65"
                    : buttonState.state === "hover" &&
                      ["secondary", "tertiary"].includes(buttonState.appearance) &&
                      (buttonState.appearance === "tertiary" || buttonState.intent === "none")
                    ? "#1D283A"
                    : buttonState.appearance === "primary" &&
                      buttonState.intent === "none" &&
                      buttonState.state === "hover" &&
                      buttonState.type === "ghost"
                    ? "#00707B"
                    : (buttonState.appearance === "secondary" && buttonState.intent === "positive" && buttonState.state === "hover") ||
                      (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "bare") ||
                      (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "ghost")
                    ? "#00AB8C"
                    : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "hover") ||
                      (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "bare") ||
                      (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "ghost")
                    ? "#D64055"
                    : (buttonState.appearance === "primary" &&
                        buttonState.intent === "none" &&
                        buttonState.state === "disabled" &&
                        buttonState.type === "bare") ||
                      (buttonState.appearance === "primary" &&
                        buttonState.intent === "none" &&
                        buttonState.state === "normal" &&
                        buttonState.type === "bare") ||
                      (buttonState.state === "disabled" && buttonState.type === "link") ||
                      (buttonState.state === "normal" && buttonState.type === "link")
                    ? "#2DB7E3"
                    : buttonState.state === "hover" &&
                      (buttonState.appearance === "primary" || buttonState.type === "link") &&
                      (buttonState.intent === "none" || buttonState.type === "link") &&
                      ["bare", "link"].includes(buttonState.type)
                    ? "#269ABF"
                    : "#00CCA7"
                }
              />
            </>
          )}

          {!buttonState.hasDropdownIndicator && <>{label}</>}
        </div>
      )}

      {(buttonState.hasAfterIcon || !buttonState.hasBeforeIcon) && <div className="text-wrapper">{label}</div>}

      {buttonState.hasDropdownIndicator && !buttonState.hasBeforeIcon && (
        <Icon
          path={mdiChevronDown}
          className={`${buttonState.size === "small" && buttonState.type === "bare" ? "class-2" : "class"}`}
          color={
            buttonState.appearance === "primary" && buttonState.type === "default"
              ? "white"
              : (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "disabled") ||
                (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "normal") ||
                (buttonState.appearance === "tertiary" && buttonState.state === "disabled") ||
                (buttonState.appearance === "tertiary" && buttonState.state === "normal")
              ? "#3F587E"
              : (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "disabled" &&
                  buttonState.type === "ghost") ||
                (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "normal" &&
                  buttonState.type === "ghost")
              ? "#00A0AF"
              : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "disabled") ||
                (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "normal") ||
                (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "ghost") ||
                (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "ghost")
              ? "#FF4C65"
              : buttonState.state === "hover" &&
                ["secondary", "tertiary"].includes(buttonState.appearance) &&
                (buttonState.appearance === "tertiary" || buttonState.intent === "none")
              ? "#1D283A"
              : buttonState.appearance === "primary" &&
                buttonState.intent === "none" &&
                buttonState.state === "hover" &&
                buttonState.type === "ghost"
              ? "#00707B"
              : (buttonState.appearance === "secondary" && buttonState.intent === "positive" && buttonState.state === "hover") ||
                (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "bare") ||
                (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "ghost")
              ? "#00AB8C"
              : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "hover") ||
                (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "ghost")
              ? "#D64055"
              : (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "disabled" &&
                  buttonState.type === "bare") ||
                (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "normal" &&
                  buttonState.type === "bare") ||
                (buttonState.state === "disabled" && buttonState.type === "link") ||
                (buttonState.state === "normal" && buttonState.type === "link")
              ? "#2DB7E3"
              : buttonState.state === "hover" &&
                (buttonState.appearance === "primary" || buttonState.type === "link") &&
                (buttonState.intent === "none" || buttonState.type === "link") &&
                ["bare", "link"].includes(buttonState.type)
              ? "#269ABF"
              : "#00CCA7"
          }
        />
      )}

      {buttonState.hasAfterIcon && (
        <Icon
          path={customAfterIcon}
          className={`${buttonState.size === "small" && buttonState.type === "bare" ? "class-2" : "class"}`}
          color={
            buttonState.appearance === "primary" && buttonState.type === "default"
              ? "white"
              : (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "disabled") ||
                (buttonState.appearance === "secondary" && buttonState.intent === "none" && buttonState.state === "normal") ||
                (buttonState.appearance === "tertiary" && buttonState.state === "disabled") ||
                (buttonState.appearance === "tertiary" && buttonState.state === "normal")
              ? "#3F587E"
              : (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "disabled" &&
                  buttonState.type === "ghost") ||
                (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "normal" &&
                  buttonState.type === "ghost")
              ? "#00A0AF"
              : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "disabled") ||
                (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "normal") ||
                (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "disabled" && buttonState.type === "ghost") ||
                (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "normal" && buttonState.type === "ghost")
              ? "#FF4C65"
              : buttonState.state === "hover" &&
                ["secondary", "tertiary"].includes(buttonState.appearance) &&
                (buttonState.appearance === "tertiary" || buttonState.intent === "none")
              ? "#1D283A"
              : buttonState.appearance === "primary" &&
                buttonState.intent === "none" &&
                buttonState.state === "hover" &&
                buttonState.type === "ghost"
              ? "#00707B"
              : (buttonState.appearance === "secondary" && buttonState.intent === "positive" && buttonState.state === "hover") ||
                (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "bare") ||
                (buttonState.intent === "positive" && buttonState.state === "hover" && buttonState.type === "ghost")
              ? "#00AB8C"
              : (buttonState.appearance === "secondary" && buttonState.intent === "error" && buttonState.state === "hover") ||
                (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "bare") ||
                (buttonState.intent === "error" && buttonState.state === "hover" && buttonState.type === "ghost")
              ? "#D64055"
              : (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "disabled" &&
                  buttonState.type === "bare") ||
                (buttonState.appearance === "primary" &&
                  buttonState.intent === "none" &&
                  buttonState.state === "normal" &&
                  buttonState.type === "bare") ||
                (buttonState.state === "disabled" && buttonState.type === "link") ||
                (buttonState.state === "normal" && buttonState.type === "link")
              ? "#2DB7E3"
              : buttonState.state === "hover" &&
                (buttonState.appearance === "primary" || buttonState.type === "link") &&
                (buttonState.intent === "none" || buttonState.type === "link") &&
                ["bare", "link"].includes(buttonState.type)
              ? "#269ABF"
              : "#00CCA7"
          }
        />
      )}
    </button>
  );
};


Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["ghost", "link", "bare", "default"]),
  appearance: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  intent: PropTypes.oneOf(["none", "error", "positive"]),
  size: PropTypes.oneOf(["large", "medium-default", "small"]),
  hasBeforeIcon: PropTypes.bool,
  hasAfterIcon: PropTypes.bool,
  hasDropdownIndicator: PropTypes.bool,
  stateProp: PropTypes.oneOf(["normal", "hover", "disabled"]),
  customBeforeIcon: PropTypes.string,
  customAfterIcon: PropTypes.string,
  onClick: PropTypes.func,
};