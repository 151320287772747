import React, { useState, useEffect } from "react";
import { mdiDomain } from '@mdi/js';
import { Input } from "../../components/Input";
import CardHeader from '../CardHeader';
import "./style.css";
import * as iban from 'iban';

export const CardPartnerBankInfo = ({
  bankInfo = {},  // Default empty object
  handleSubmit,
  onBankInfoChange,
  editButton = true,
  context='editor',
  edit=false,
}) => {

  const [isEditing, setIsEditing] = useState(edit);
  const [_bankInfo, setBankInfo] = useState(bankInfo || {});
  const [ibanValid, setIbanValid] = useState(true);

  useEffect(() => {
    setBankInfo(bankInfo || {});  // Ensure bankInfo is always an object
  }, [bankInfo]);

  const validateIBAN = (ibanValue) => {
    return iban.isValid(ibanValue);
  };

  const extractBankDetails = (ibanValue) => {
    if (!validateIBAN(ibanValue)) {
      return null;
    }

    const formattedIban = iban.electronicFormat(ibanValue);

    const bankid = formattedIban.substring(4, 9);
    const branch = formattedIban.substring(9, 14);
    const accountnbr = formattedIban.substring(14, 25);
    const key = formattedIban.substring(25, 27);

    return {
      bankid,
      branch,
      accountnbr,
      key
    };
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newBankInfo = { ..._bankInfo, [name]: value };

    if (name === "iban") {
      const isValid = validateIBAN(value);
      setIbanValid(isValid);
      if (isValid) {
        const bankDetails = extractBankDetails(value);
        if (bankDetails) {
          newBankInfo = { ...newBankInfo, ...bankDetails };
        }
      } else {
        newBankInfo = { ...newBankInfo, bankid: '', branch: '', accountnbr: '', key: '' };
      }
    }

    setBankInfo(newBankInfo);
    if (onBankInfoChange) {
      onBankInfoChange(newBankInfo);  // Notify parent of changes
    }
  };

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();  // Trigger handleSubmit from parent
    }
    setIsEditing(!isEditing);
  };

  const handleDiscard = () => {
    setBankInfo(bankInfo || {});  // Reset to original bankInfo, ensuring it's not null
    if (onBankInfoChange) {
      onBankInfoChange(bankInfo);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className={context==='editor' ?('card-component'):('drawer-rfp-form-bloc')}>
      <CardHeader
        isEditing={!isEditing}
        context={context}
        editButton={editButton}
        iconPath={mdiDomain}
        title='Coordonnées bancaires'
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
            <div className={context==='editor' ?('card-content card-content-v-padding'):('card-content card-padding-fix')}>

        <div className='card-row-3'>
          <div className='card-languages-left'>
            <div className="card-label">
              IBAN :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <div>
                  <Input type="text"
                    name="iban"
                    value={_bankInfo.iban || ''}  // Safeguard against null or undefined
                    onChange={handleInputChange}
                    className="input-normal"
                  />
                  {!ibanValid && <div className="error-message">Invalid IBAN</div>}
                </div>
              ) : (_bankInfo.iban || '')}  {/* Display empty string if undefined */}
            </div>
          </div>
          <div>
            <div className="card-label">
              BIC :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="bic"
                  value={_bankInfo.bic || ''}  // Safeguard against null or undefined
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : (_bankInfo.bic || '')}  {/* Display empty string if undefined */}
            </div>
          </div>
          <div>
            <div className="card-label">
              Code Banque :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="bankid"
                  value={_bankInfo.bankid || ''}  // Safeguard against null or undefined
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : (_bankInfo.bankid || '')}  {/* Display empty string if undefined */}
            </div>
          </div>
        </div>
        <div className='card-row-3'>
          <div className='card-languages-left'>
            <div className="card-label">
              Code agence :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="branch"
                  value={_bankInfo.branch || ''}  // Safeguard against null or undefined
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : (_bankInfo.branch || '')}  {/* Display empty string if undefined */}
            </div>
          </div>
          <div>
            <div className="card-label">
              N° de compte :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="accountnbr"
                  value={_bankInfo.accountnbr || ''}  // Safeguard against null or undefined
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : (_bankInfo.accountnbr || '')}  {/* Display empty string if undefined */}
            </div>
          </div>
          <div>
            <div className="card-label">
              Clé RIB :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="key"
                  value={_bankInfo.key || ''}  // Safeguard against null or undefined
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : (_bankInfo.key || '')}  {/* Display empty string if undefined */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
