// Home.js
import React, { useState } from 'react';
import ClientList from '../mycomponents/ClientList';
import Drawer from '@mui/material/Drawer';
import {mdiPlus } from '@mdi/js';
import { Button } from '../components/Button';
import CreateClientPage from './CreateClient';

const ListClientPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const openDrawer = (applicationId) => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const refreshClientList = () => {
    setRefreshKey(oldKey => oldKey + 1);  // Trigger re-render of ClientList
    closeDrawer();  // Close the drawer after successful creation
  };

  return (
    <div className='list-rfp-container'>
      <div className='list-rfp-header'>
        <div className='list-rfp-header-content-l'>
          Clients
        </div>
        <div className='list-rfp-header-content-r'>
          
            <Button
              appearance="primary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={true}
              hasDropdownIndicator={false}
              intent="none"
              label="Nouveau client"
              size="medium-default"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiPlus}
              customAfterIcon={mdiPlus}
              onClick={() => openDrawer()}
            />

        </div>
      </div>
    <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>   
    <ClientList key={refreshKey} />
  </div>
  <div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={closeDrawer}
        >
         <div><CreateClientPage onSuccess={refreshClientList} /></div>
        </Drawer>
      </div>
  </div>
  );
}

export default ListClientPage;
