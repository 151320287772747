import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import Icon from '@mdi/react';
import { Counter } from "../Counter";
import "./style.css";
import { useNavigate } from "react-router-dom";

const SideNavItem = ({
  path,
  icon = true,
  counter = true,
  label = 'label',
  override,
  isCollapse,
  className,
  customIcon,
  active = false,
}) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    hover: false,
    active: active,
  });

  // Sync internal "active" state with the prop
  useEffect(() => {
    dispatch({ type: active ? "activate" : "deactivate" });
  }, [active]);

  const handleMouseEnter = (event) => {
    event.stopPropagation();
    dispatch({ type: "mouse_enter" });
  };

  const handleMouseLeave = (event) => {
    event.stopPropagation();
    dispatch({ type: "mouse_leave" });
  };

  const handleClick = (event) => {
    event.stopPropagation();
    navigate(path);
  };

  const itemClassName = `side-nav-item ${state.active ? 'active' : ''} ${state.hover ? 'hover' : ''} ${className}`;

  return (
    <div
      className={itemClassName}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
    >
      {icon && (
        <>
          {isCollapse && (
            <Icon
              path={customIcon}
              size={1}
              className="custom-icon"
              color={state.active ? "white" : "#3F587E"}
            />
          )}
          {!isCollapse && (
            <>
              {customIcon ? (
                <Icon
                  path={customIcon}
                  size={1}
                  className="custom-icon"
                  color={state.active ? "white" : "#3F587E"}
                />
              ) : (
                override
              )}
            </>
          )}
        </>
      )}
      {!isCollapse && (
        <>
          <div className="text-wrapper">{label}</div>
          {counter && (
            <Counter
              className="counter-instance"
              color={state.active ? "white" : "red"}
              emphasis="high"
              number="00"
              size="medium"
            />
          )}
        </>
      )}
    </div>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case "mouse_enter":
      return { ...state, hover: true };
    case "mouse_leave":
      return { ...state, hover: false };
    case "activate":
      return { ...state, active: true };
    case "deactivate":
      return { ...state, active: false };
    default:
      return state;
  }
}

SideNavItem.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.bool,
  label: PropTypes.string,
  counter: PropTypes.bool,
  isCollapse: PropTypes.bool,
  className: PropTypes.string,
  customIcon: PropTypes.string,
  path: PropTypes.string,
};

export default SideNavItem;
