// ListRfps.js
import React, { useState } from 'react';
import RfpList from '../mycomponents/RfpList';
import RfpListClient from '../mycomponents/RfpList';
import { Button } from '../components/Button';
import { mdiPlus } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';

import CreateRfpForm from '../mycomponents/CreateRfpForm';
import { useAuth } from '../middlewares/useAuth';
const ListRfpsPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = (applicationId) => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const { userRoles, client_id, partner_id } = useAuth();
  const isAdmin = userRoles.includes('admin');

  return (
    <div className='list-rfp-container'>
      <div className='list-rfp-header'>
        <div className='list-rfp-header-content-l'>
          Appels d'offres
        </div>
        <div className='list-rfp-header-content-r'>
          <span onClick={() => openDrawer()} >
            {isAdmin && (
              <Button
                appearance="primary"
                className="button-instance"
                hasAfterIcon={false}
                hasBeforeIcon={true}
                hasDropdownIndicator={false}
                intent="none"
                label="Créer un appel d'offre"
                size="medium-default"
                stateProp="normal"
                type="default"
                customBeforeIcon={mdiPlus}
                customAfterIcon={mdiPlus}
              />
            )}
          </span>
        </div>
      </div>
      <div>
      {isAdmin &&(
        <RfpList />
      )}
      {userRoles.includes('client') &&(
        <RfpListClient clientId={client_id} />
      )}
      {userRoles.includes('partner') &&(
        <RfpList partner_id={partner_id} />
      )}
      </div>
      <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open={drawerOpen}
        onClick={() => {}} // Do nothing on backdrop click
      />
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={closeDrawer}
          hideBackdrop={true}
        >
          <CreateRfpForm closeDrawer={closeDrawer} />
        </Drawer>
      </div>

    </div>
  );
}

export default ListRfpsPage;
