/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const LogoEquity1 = ({ className }) => {
  return (
    <svg
      className={`logo-equity-1 ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 90 32"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M78.4313 7.86592H73.8V19.1152C73.8 19.6519 73.877 20.1647 74.0368 20.6489C74.191 21.1163 74.401 21.541 74.6721 21.9161L74.6788 21.9254L74.6858 21.9344C74.9728 22.3054 75.3168 22.6086 75.7178 22.8351C76.135 23.0707 76.5875 23.1932 77.0647 23.1932H81.1169C81.6333 23.1932 82.119 23.0581 82.5639 22.8003V26.692H76.7078V30.3911H83.9305C84.411 30.3911 84.8632 30.2623 85.2743 30.0138C85.674 29.786 86.0152 29.4782 86.299 29.1017C86.5867 28.7289 86.8041 28.2974 86.9592 27.818C87.1185 27.3343 87.1952 26.8223 87.1952 26.2864V23.1731C87.4655 23.1411 87.7281 23.071 87.981 22.9627L87.99 22.9588L87.9988 22.9547C88.3392 22.7978 88.6435 22.5887 88.908 22.3285C89.1828 22.0671 89.4043 21.7623 89.5729 21.4191C89.7516 21.0646 89.8648 20.6901 89.91 20.2988L90 19.5208H87.1952V7.86592H82.5639V19.5208H78.4313V7.86592Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M72.18 19.5268H67.272V11.2266H70.8956V7.74186H67.272V1.96648L62.6377 4.27091V7.74186H61.56V11.2266H62.6377V19.1167C62.6377 19.6593 62.7147 20.1778 62.8746 20.6675C63.0289 21.1401 63.2391 21.5696 63.5103 21.9489L63.517 21.9582L63.524 21.9674C63.8112 22.3425 64.1555 22.6491 64.5567 22.8781C64.9742 23.1163 65.4269 23.2402 65.9045 23.2402H69.0163C69.4085 23.2402 69.7872 23.1625 70.1462 23.0071L70.1552 23.0032L70.1641 22.9991C70.5039 22.8407 70.8106 22.6307 71.0818 22.3712C71.3593 22.1056 71.5828 21.7956 71.7526 21.4462C71.9314 21.0878 72.0447 20.7092 72.09 20.3135L72.18 19.5268Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M54.1437 5.70184L54.1475 5.7035L54.1513 5.70511C54.5124 5.85917 54.8942 5.9345 55.2904 5.9345C55.6805 5.9345 56.0577 5.8581 56.4163 5.70511C56.7692 5.55456 57.0829 5.34291 57.351 5.07015C57.6142 4.80244 57.8213 4.49273 57.97 4.14518C58.1334 3.77532 58.214 3.38245 58.214 2.97396C58.214 2.56849 58.1345 2.17951 57.97 1.81602C57.8224 1.46216 57.616 1.14735 57.351 0.877781C57.0861 0.608222 56.7766 0.398354 56.4287 0.248154C56.0714 0.0808377 55.689 0 55.2904 0C54.8888 0 54.5026 0.082006 54.1389 0.248196C53.7973 0.399498 53.4929 0.610086 53.2297 0.877781C52.9625 1.14958 52.755 1.46738 52.6071 1.82483C52.4535 2.18682 52.38 2.57258 52.38 2.97396C52.38 3.37692 52.4541 3.76535 52.6055 4.13271L52.6071 4.13656L52.6087 4.14038C52.7576 4.48989 52.9653 4.8012 53.2297 5.07015C53.4941 5.33909 53.8001 5.55039 54.1437 5.70184Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M57.5936 7.77348H53.0531V19.1251C53.0531 19.6666 53.1286 20.1841 53.2852 20.6728C53.4379 21.1491 53.6508 21.5813 53.9289 21.9621L53.9318 21.9661L53.9347 21.97C54.2161 22.3443 54.5534 22.6503 54.9465 22.8788C55.3555 23.1166 55.7991 23.2402 56.267 23.2402H57.4285C57.8127 23.2402 58.1838 23.1626 58.5356 23.0076L58.5444 23.0037L58.5531 22.9995C58.8867 22.8412 59.1851 22.6302 59.4444 22.3676C59.7138 22.1038 59.931 21.7962 60.0963 21.4499C60.2742 21.0865 60.3816 20.7017 60.4155 20.2996L60.48 19.5344H57.5936V7.77348Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M39.7275 7.86592H35.1V19.1497C35.1 19.688 35.1769 20.2024 35.3365 20.6881C35.4906 21.1569 35.7005 21.583 35.9714 21.9592L35.978 21.9685L35.985 21.9776C36.2718 22.3496 36.6156 22.6538 37.0162 22.881C37.433 23.1173 37.8851 23.2402 38.362 23.2402H42.4646C42.7601 23.2402 43.049 23.18 43.3273 23.0719L43.3344 23.0691L43.3416 23.0662C43.6134 22.9538 43.8676 22.8079 44.1033 22.6298L44.1071 22.6269C44.2784 22.4955 44.4357 22.3488 44.5788 22.1875L45.2305 23.2402H48.1544C48.546 23.2402 48.9241 23.1631 49.2826 23.009L49.2916 23.0051L49.3005 23.001C49.6405 22.8435 49.9446 22.6339 50.2089 22.3729C50.4834 22.1106 50.7048 21.8048 50.8732 21.4606C51.0518 21.105 51.1649 20.7294 51.2101 20.3369L51.3 19.5565H48.4841V7.86592H43.87V19.5565H39.7275V7.86592Z"
        fill="#00A0AF"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M17.803 9.1458L17.801 9.14855C17.525 9.51812 17.3128 9.94164 17.1576 10.4102C16.9972 10.8945 16.92 11.4073 16.92 11.944V19.1152C16.92 19.6519 16.9972 20.1647 17.1576 20.6489C17.3124 21.1163 17.5232 21.541 17.7952 21.9161L17.8019 21.9254L17.809 21.9344C18.097 22.3054 18.4423 22.6086 18.8447 22.8351C19.2634 23.0707 19.7175 23.1932 20.1965 23.1932H23.939C24.2448 23.1932 24.5485 23.1367 24.8466 23.0351C25.1298 22.9385 25.4039 22.811 25.6686 22.6546L25.6774 22.6494L25.686 22.644C25.6959 22.6378 25.7057 22.6315 25.7155 22.6252V30.3911H30.3635V23.1849C30.6776 23.1612 30.9821 23.0871 31.2737 22.9627L31.2827 22.9588L31.2916 22.9547C31.6331 22.7978 31.9386 22.5888 32.204 22.3286C32.4797 22.0672 32.702 21.7625 32.8711 21.4194C33.0506 21.0649 33.1643 20.6903 33.2097 20.2988L33.3 19.5208H30.3635V7.86592H20.1965C19.7175 7.86592 19.2634 7.98847 18.8447 8.22407C18.4377 8.45312 18.0908 8.76432 17.803 9.1458ZM25.7155 11.5383V19.5208H21.5545V11.5383H25.7155Z"
        fill="#00A0AF"
        fillRule="evenodd"
      />
      <path
        className="path"
        clipRule="evenodd"
        d="M1.9181 8.22517C1.51245 8.45492 1.16679 8.76707 0.880001 9.14973L0.877946 9.15249C0.602943 9.52319 0.391427 9.948 0.2368 10.418C0.0769854 10.9038 0 11.4181 0 11.9565V19.1497C0 19.688 0.0769854 20.2024 0.2368 20.6881C0.391023 21.1569 0.601099 21.583 0.872231 21.9592L0.87893 21.9685L0.885931 21.9776C1.173 22.3496 1.51708 22.6538 1.9181 22.881C2.33535 23.1173 2.78789 23.2402 3.26525 23.2402H10.5313C10.9233 23.2402 11.3018 23.1631 11.6607 23.009L11.6697 23.0051L11.6786 23.001C12.0189 22.8436 12.3233 22.6339 12.5878 22.3729C12.8626 22.1107 13.0841 21.805 13.2527 21.4608C13.4315 21.1052 13.5448 20.7295 13.59 20.3369L13.68 19.5565H4.63204V17.1013H13.68V11.9565C13.68 11.4181 13.603 10.9038 13.4432 10.418C13.2876 9.94493 13.0698 9.51783 12.7836 9.14583C12.4983 8.76616 12.1549 8.45612 11.7523 8.22735C11.3408 7.9883 10.8909 7.86592 10.4148 7.86592H3.26525C2.78789 7.86592 2.33535 7.98884 1.9181 8.22517ZM4.63204 13.8313V11.5496H9.04796V13.8313H4.63204Z"
        fill="#00A0AF"
        fillRule="evenodd"
      />
      <path
        className="path"
        d="M56.8027 27.9996V29.8128C56.7309 29.8987 56.6202 29.992 56.4704 30.0925C56.3207 30.191 56.1277 30.2758 55.8914 30.3471C55.6552 30.4183 55.3693 30.4539 55.0339 30.4539C54.7365 30.4539 54.4655 30.4057 54.2208 30.3094C53.9761 30.2109 53.7652 30.0674 53.588 29.8788C53.4129 29.6903 53.2779 29.4598 53.183 29.1875C53.088 28.9131 53.0406 28.5999 53.0406 28.2479V27.9619C53.0406 27.61 53.0859 27.2968 53.1766 27.0223C53.2695 26.7479 53.4013 26.5164 53.5722 26.3279C53.743 26.1393 53.9466 25.9958 54.1828 25.8973C54.4191 25.7989 54.6817 25.7496 54.9707 25.7496C55.3714 25.7496 55.7005 25.8146 55.9579 25.9445C56.2152 26.0723 56.4114 26.2503 56.5464 26.4787C56.6835 26.705 56.7679 26.9647 56.7995 27.258H55.8788C55.8555 27.103 55.8113 26.9679 55.7459 26.8527C55.6805 26.7374 55.5866 26.6473 55.4643 26.5824C55.344 26.5175 55.1879 26.485 54.996 26.485C54.8378 26.485 54.6964 26.5175 54.572 26.5824C54.4497 26.6452 54.3463 26.7385 54.2619 26.8621C54.1775 26.9857 54.1132 27.1386 54.0689 27.3209C54.0246 27.5031 54.0025 27.7147 54.0025 27.9557V28.2479C54.0025 28.4867 54.0257 28.6983 54.0721 28.8827C54.1185 29.0649 54.187 29.2189 54.2777 29.3446C54.3705 29.4682 54.4845 29.5615 54.6195 29.6243C54.7545 29.6871 54.9116 29.7186 55.0909 29.7186C55.2407 29.7186 55.3651 29.706 55.4643 29.6809C55.5655 29.6557 55.6467 29.6253 55.7079 29.5897C55.7712 29.552 55.8197 29.5164 55.8534 29.4829V28.6721H54.9865V27.9996H56.8027Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M58.4227 27.7325V30.3911H57.5114V26.9909H58.3689L58.4227 27.7325ZM59.4541 26.9689L59.4383 27.808C59.394 27.8017 59.3402 27.7964 59.277 27.7922C59.2158 27.786 59.1599 27.7828 59.1093 27.7828C58.9806 27.7828 58.8688 27.7996 58.7739 27.8331C58.6811 27.8645 58.603 27.9117 58.5397 27.9745C58.4786 28.0374 58.4322 28.1138 58.4005 28.2039C58.371 28.294 58.3541 28.3966 58.3499 28.5119L58.1664 28.4553C58.1664 28.2353 58.1885 28.0332 58.2328 27.8488C58.2771 27.6624 58.3414 27.5 58.4258 27.3617C58.5123 27.2235 58.6178 27.1166 58.7422 27.0412C58.8667 26.9658 59.0091 26.9281 59.1694 26.9281C59.22 26.9281 59.2717 26.9323 59.3244 26.9406C59.3772 26.9469 59.4204 26.9564 59.4541 26.9689Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M59.644 28.7256V28.6596C59.644 28.4103 59.6798 28.1809 59.7516 27.9714C59.8233 27.7598 59.9277 27.5765 60.0648 27.4214C60.2019 27.2664 60.3707 27.1459 60.5711 27.0601C60.7714 26.9721 61.0014 26.9281 61.2608 26.9281C61.5203 26.9281 61.7513 26.9721 61.9538 27.0601C62.1563 27.1459 62.3261 27.2664 62.4632 27.4214C62.6024 27.5765 62.7079 27.7598 62.7796 27.9714C62.8513 28.1809 62.8872 28.4103 62.8872 28.6596V28.7256C62.8872 28.9728 62.8513 29.2022 62.7796 29.4138C62.7079 29.6233 62.6024 29.8066 62.4632 29.9637C62.3261 30.1187 62.1573 30.2392 61.9569 30.3251C61.7565 30.411 61.5266 30.4539 61.2672 30.4539C61.0077 30.4539 60.7767 30.411 60.5742 30.3251C60.3738 30.2392 60.204 30.1187 60.0648 29.9637C59.9277 29.8066 59.8233 29.6233 59.7516 29.4138C59.6798 29.2022 59.644 28.9728 59.644 28.7256ZM60.5552 28.6596V28.7256C60.5552 28.868 60.5679 29.001 60.5932 29.1246C60.6185 29.2483 60.6586 29.3572 60.7134 29.4515C60.7704 29.5436 60.8442 29.6159 60.9349 29.6683C61.0256 29.7207 61.1364 29.7469 61.2672 29.7469C61.3937 29.7469 61.5023 29.7207 61.5931 29.6683C61.6838 29.6159 61.7565 29.5436 61.8114 29.4515C61.8662 29.3572 61.9063 29.2483 61.9316 29.1246C61.959 29.001 61.9727 28.868 61.9727 28.7256V28.6596C61.9727 28.5213 61.959 28.3914 61.9316 28.2699C61.9063 28.1463 61.8652 28.0374 61.8082 27.9431C61.7534 27.8467 61.6806 27.7713 61.5899 27.7168C61.4992 27.6624 61.3895 27.6351 61.2608 27.6351C61.1322 27.6351 61.0225 27.6624 60.9318 27.7168C60.8432 27.7713 60.7704 27.8467 60.7134 27.9431C60.6586 28.0374 60.6185 28.1463 60.5932 28.2699C60.5679 28.3914 60.5552 28.5213 60.5552 28.6596Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M65.4722 29.5772V26.9909H66.3834V30.3911H65.526L65.4722 29.5772ZM65.5734 28.8795L65.8424 28.8733C65.8424 29.0995 65.816 29.3101 65.7633 29.5049C65.7105 29.6976 65.6314 29.8652 65.526 30.0077C65.4205 30.148 65.2876 30.258 65.1273 30.3376C64.967 30.4152 64.7782 30.4539 64.5609 30.4539C64.3943 30.4539 64.2403 30.4309 64.099 30.3848C63.9598 30.3366 63.8395 30.2622 63.7383 30.1617C63.6391 30.059 63.5611 29.9281 63.5041 29.7689C63.4493 29.6075 63.4219 29.4138 63.4219 29.1875V26.9909H64.3331V29.1938C64.3331 29.2943 64.3447 29.3792 64.3679 29.4483C64.3932 29.5175 64.4281 29.574 64.4723 29.618C64.5166 29.662 64.5683 29.6934 64.6274 29.7123C64.6886 29.7311 64.7561 29.7406 64.8299 29.7406C65.0176 29.7406 65.1653 29.7029 65.2729 29.6274C65.3825 29.552 65.4595 29.4494 65.5038 29.3195C65.5502 29.1875 65.5734 29.0409 65.5734 28.8795Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M67.9908 27.6446V31.6983H67.0795V26.9909H67.9243L67.9908 27.6446ZM70.1582 28.6533V28.7193C70.1582 28.9665 70.1286 29.1959 70.0696 29.4075C70.0126 29.6191 69.9282 29.8034 69.8164 29.9605C69.7047 30.1156 69.5654 30.2371 69.3988 30.3251C69.2343 30.411 69.0444 30.4539 68.8293 30.4539C68.6204 30.4539 68.439 30.412 68.285 30.3282C68.1311 30.2444 68.0013 30.1271 67.8959 29.9763C67.7925 29.8233 67.7092 29.6463 67.6459 29.4452C67.5826 29.2441 67.5341 29.0283 67.5004 28.7978V28.625C67.5341 28.3778 67.5826 28.1515 67.6459 27.9462C67.7092 27.7388 67.7925 27.5597 67.8959 27.4089C68.0013 27.2559 68.13 27.1376 68.2819 27.0538C68.4359 26.97 68.6162 26.9281 68.8229 26.9281C69.0402 26.9281 69.2311 26.9689 69.3956 27.0506C69.5623 27.1323 69.7015 27.2496 69.8133 27.4026C69.9272 27.5555 70.0126 27.7378 70.0696 27.9494C70.1286 28.161 70.1582 28.3956 70.1582 28.6533ZM69.2438 28.7193V28.6533C69.2438 28.5087 69.2311 28.3757 69.2058 28.2542C69.1826 28.1306 69.1446 28.0227 69.0919 27.9305C69.0413 27.8383 68.9738 27.7671 68.8894 27.7168C68.8071 27.6645 68.7069 27.6383 68.5888 27.6383C68.4643 27.6383 68.3578 27.6582 68.2692 27.698C68.1827 27.7378 68.1121 27.7954 68.0572 27.8708C68.0024 27.9462 67.9613 28.0363 67.9338 28.1411C67.9064 28.2458 67.8895 28.3642 67.8832 28.4962V28.933C67.8938 29.088 67.9233 29.2273 67.9718 29.3509C68.0203 29.4724 68.0952 29.5688 68.1964 29.64C68.2977 29.7112 68.4306 29.7469 68.5951 29.7469C68.7154 29.7469 68.8166 29.7207 68.8989 29.6683C68.9811 29.6138 69.0476 29.5395 69.0982 29.4452C69.1509 29.3509 69.1879 29.242 69.2089 29.1184C69.2322 28.9948 69.2438 28.8617 69.2438 28.7193Z"
        fill="#566263"
      />
      <path
        className="path"
        d="M72.3002 30.4539C72.0345 30.4539 71.7961 30.412 71.5852 30.3282C71.3742 30.2423 71.1949 30.124 71.0473 29.9731C70.9017 29.8223 70.7899 29.6473 70.7119 29.4483C70.6338 29.2472 70.5948 29.0335 70.5948 28.8073V28.6816C70.5948 28.4239 70.6317 28.1882 70.7055 27.9745C70.7794 27.7608 70.8848 27.5754 71.022 27.4183C71.1612 27.2612 71.3299 27.1407 71.5282 27.0569C71.7265 26.971 71.9501 26.9281 72.199 26.9281C72.4416 26.9281 72.6567 26.9679 72.8445 27.0475C73.0322 27.1271 73.1893 27.2402 73.3159 27.3869C73.4446 27.5335 73.5416 27.7095 73.607 27.9148C73.6724 28.118 73.7051 28.3443 73.7051 28.5936V28.9707H70.984V28.3673H72.8097V28.2982C72.8097 28.1725 72.7864 28.0604 72.74 27.9619C72.6957 27.8614 72.6282 27.7818 72.5375 27.7231C72.4468 27.6645 72.3308 27.6351 72.1895 27.6351C72.0693 27.6351 71.9659 27.6613 71.8794 27.7137C71.7929 27.7661 71.7223 27.8394 71.6674 27.9337C71.6147 28.0279 71.5746 28.139 71.5472 28.2668C71.5219 28.3925 71.5092 28.5307 71.5092 28.6816V28.8073C71.5092 28.9434 71.5282 29.0691 71.5662 29.1844C71.6063 29.2996 71.6622 29.3991 71.7339 29.4829C71.8077 29.5667 71.8963 29.6316 71.9997 29.6777C72.1051 29.7238 72.2243 29.7469 72.3572 29.7469C72.5217 29.7469 72.6747 29.7154 72.816 29.6526C72.9594 29.5876 73.0828 29.4902 73.1862 29.3603L73.6291 29.838C73.5574 29.9406 73.4593 30.0391 73.3349 30.1334C73.2125 30.2277 73.0649 30.3052 72.8919 30.3659C72.7189 30.4246 72.5217 30.4539 72.3002 30.4539Z"
        fill="#566263"
      />
    </svg>
  );
};
