// CreateProposal.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CardRfpTitle } from '../mycomponents/CardRfpTitle';
import ApplicantListAndApply from '../mycomponents/ApplicantListAndApply';
import EditApplicant from '../mycomponents/EditApplicant';
import RfpRequirements from '../mycomponents/RfpRequirements';
import {CardRfpApplicationProposal} from '../mycomponents/CardRfpApplicationProposal'; // Ensure correct import
import { WizardItems } from "../components/WizardItems";
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { useNavigate } from "react-router-dom";

const CreateProposal = () => {
    const { id } = useParams();
    const [wizardStep, setWizardStep] = useState(0);
    const [contractDetails, setContractDetails] = useState( { partner_margin_rate: '0', client_margin_rate: '0', comment: '' });
    const [rate, setRate] = useState();
    const [selectedApplicantId, setSelectedApplicantId] = useState(null);
    const [rfp, setRfp] = useState({});
    const [clientName, setClientName] = useState();
    const [applicant, setApplicant] = useState({});
    const [localComment, setLocalComment] = useState('');
    const [rfpState, setRfpState] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();
    const auth = useAuth();
    
    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    const { userRoles } = useAuth();
    const isAdmin = userRoles.includes('admin');

    useEffect(() => {
        if (auth.initialized) {
            const fetchRfpDetails = async () => {
                try {
                    const response = await api.get(`/rfp/${id}`);
                    setRfp(response.data);
                    setRfpState({
                        diplomas: response.data.diplomas?.map(diploma => ({ ...diploma, candidateLevel: '', comment: '' })),
                        languages: response.data.languages?.map(language => ({ ...language, candidateLevel: '', comment: '' })),
                        skills: response.data.skills?.map(skill => ({ ...skill, candidateLevel: '', comment: '' }))
                    });
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false); // End loading
                }
            };
            fetchRfpDetails();
        }
        if (auth.initialized && rfp.id_client) {
            fetchClientName(rfp.id_client);
        }
    }, [auth.initialized, id, rfp.id_client]);

    const fetchClientName = async (client_id) => {
        try {
            const response = await api.get(`/client/${client_id}`);
            setClientName(response.data.name);
        } catch (error) {
            console.error('Error fetching client name:', error);
        }
    };

    const handleApplicantSelect = (applicantId) => {
        setSelectedApplicantId(applicantId);
        setWizardStep(wizardStep + 1);
    };

    const handlePrevNextStep = (step) => {
        setWizardStep(step);
    };

    const handleApplicantUpdate = (updatedApplicant) => {
        // Check if the update is necessary
        if (updatedApplicant.rate !== rate || updatedApplicant !== applicant) {
            setApplicant(updatedApplicant);
            setRate(updatedApplicant.rate);
            setContractDetails(prevDetails => ({ ...prevDetails, rate: updatedApplicant.rate }));
        }
    };
    

    const onSaveContractDetails = (updatedDetails) => {
        setContractDetails(updatedDetails);
    };

    const handleSaveLocalComment = (comment) => {
        setLocalComment(comment);
    };

    const collectState = () => {
        return {
            applicant_id: selectedApplicantId,
            rfp_id: id,
            partner_id: applicant.partner_id,
            status: 'pending',
            resume: applicant.resume,
            label: applicant.label,
            rate: contractDetails.rate,
            last_name: applicant.last_name,
            first_name: applicant.first_name,
            trigraph: applicant.trigraph,
            birth_date: applicant.birth_date,
            available_date: applicant.available_date,
            birth_place: applicant.birth_place,
            residence: applicant.residence,
            comment: contractDetails.comment,
            offer_details: contractDetails,
            assessment: rfpState,
            rfpState: rfpState,
        };
    };
    let isSubmitting = false;
    const handleSubmit = async () => {
        if (isSubmitting) return;
        isSubmitting = true;
        const stateData = collectState();
        try {
            console.log('PUT', stateData)
            await api.put(`/application/${id}`, stateData);
            navigate(`/rfp/${id}`);
        } catch (error) {
            console.error('Error submitting proposal:', error);
        } finally {
            isSubmitting = false;
        }
    };

    const wizardStates = [
        ["active", "inactive", "inactive", "inactive", "Sélectionnez votre candidat pour répondre à l’appel d’offre", 4],
        ["completed", "active", "inactive", "inactive", "Vérifiez et/ou complétez les informations sur le candidat", 5],
        ["completed", "completed", "active", "inactive", "Complétez les informations de compétences sur le candidats", 6],
        ["completed", "completed", "completed", "active", "Remplissez la proposition commerciale et la commission", 7]
    ];

    if (loading) { // Render loading state
        return //<div className='center'><LogoEquity1 className="logo-equity" /></div>;
    }
    
    return (
        <div>
            <CardRfpTitle
                rfp_title={rfp.rfp_title}
                client_name={clientName}
                client_ref={rfp.client_ref}
                eq_ref="ref eq"
                rfp_id={`${id}`}
                isEditing={false}
                status={wizardStates[wizardStep][5]}
                updateStatus={handlePrevNextStep}
                finalize={handleSubmit}
            />
            <div className='flex-cont'>
                <div className="wizard-cont">
                    <WizardItems
                        className="wizard-items-instance"
                        label="Choix du candidat"
                        overlapGroupClassName="design-component-instance-node"
                        state={wizardStates[wizardStep][0]}
                        type="initial"
                    />
                    <WizardItems
                        className="wizard-items-2"
                        label="Informations candidats"
                        overlapGroupClassName="wizard-items-3"
                        state={wizardStates[wizardStep][1]}
                        type="middle"
                    />
                    <WizardItems
                        className="wizard-items-2"
                        label="Comparatif compétences de l’AO"
                        overlapGroupClassName="wizard-items-3"
                        state={wizardStates[wizardStep][2]}
                        type="middle"
                    />
                    <WizardItems
                        className="wizard-items-2"
                        label="Proposition commerciale"
                        overlapGroupClassName="wizard-items-4"
                        state={wizardStates[wizardStep][3]}
                        type="end"
                    />
                </div>
            </div>
            <div className='wizard-section-header'>
                {wizardStates[wizardStep][4]}
            </div>
            <div className="alert-wrapper">
                <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                    {(selectedApplicantId === null || wizardStep === 0) && (
                        <ApplicantListAndApply rfpId={`${id}`} mode="addApplication" onSelectApplicant={handleApplicantSelect} />
                    )}
                    {selectedApplicantId !== null && wizardStep === 1 && (
                        <EditApplicant id={selectedApplicantId} onUpdateApplicant={handleApplicantUpdate} />
                    )}
                    {selectedApplicantId !== null && wizardStep === 2 && rfpState && (
                        <RfpRequirements state={rfpState} onStateChange={setRfpState} isAdmin={isAdmin}/>
                    )}
                    {selectedApplicantId !== null && wizardStep === 3 && (    
                             <CardRfpApplicationProposal
                                rate={rate}
                                contractDetails={contractDetails}
                                onSaveContractDetails={onSaveContractDetails}
                                localComment={localComment}
                                onSaveLocalComment={handleSaveLocalComment}
                                editing={true}
                                isAdmin={isAdmin}
                                applicantId = {selectedApplicantId}
                            />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateProposal;
