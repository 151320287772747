// NezRfpq.js
import React from 'react';
import CreateRfpForm from '../mycomponents/CreateRfpForm';

const CreateRfpPage = () => {
  return (
    <div>
      <CreateRfpForm />
    </div>
  );
}

export default CreateRfpPage;
