import React, { useState, useCallback } from 'react';
import { mdiPlus, mdiDeleteForeverOutline } from '@mdi/js';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { IconButton } from '../../components/IconButton';
import { arraysEqual } from '../../utils/dataTools';
import CardHeader from '../CardHeader';

export const CardCompanyMarkets = ({
  experiences: markets = [],
  saveMarkets,
  iconPath,
  title,
  editButton = true,
  handleSubmit,
  lCollapsed = true,
  context = 'editor',
}) => {

  const [isEditing, setIsEditing] = useState(lCollapsed);

  const handleChange = useCallback((index, name, value) => {
    if (index === null) {

    } else {
      const updatedExperiences = markets.map((skill, idx) => idx === index ? { ...skill, [name]: value } : skill);
      if (!arraysEqual(markets, updatedExperiences)) {
        saveMarkets(updatedExperiences);
      }
    }
  }, [markets, saveMarkets]);

  const handleAddExperience = () => {
    const currentExperiences = Array.isArray(markets) ? markets : [];
    let updatedExperiences = [...currentExperiences];
    updatedExperiences.push({ title: '', company: '', startDate: '', endDate: '', description: '' });
    saveMarkets(updatedExperiences);
  };

  const handleDelete = (index) => {
    const updatedExperiences = markets.filter((_, idx) => idx !== index);
    saveMarkets(updatedExperiences);
  };

  const handleDiscard = () => {
    saveMarkets(markets);
    setIsEditing(!isEditing);
  }

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();
    }
    setIsEditing(!isEditing);
  }

  return (
    <div className={context === 'editor' ? ('card-component') : ('drawer-rfp-form-bloc')}>
      {context === 'editor' ? (
        <CardHeader
          isEditing={!isEditing}
          context='editor'
          editButton={editButton}
          iconPath={iconPath}
          title={title}
          handleDiscard={handleDiscard}
          toggleEdit={toggleEdit}
        />
      ) : (
        <div className='header-xsmall'>
          {title}
        </div>
      )}
      <div className={context === 'editor' ? ('card-content v-padding-s') : ('card-content card-padding-fix')}>
        <div className="card-languages-list">
          <ul>
            {Array.isArray(markets) && markets.map((skill, index) => (
              <li key={index} className='force-no-border v-padding-z'>
                {!isEditing ? (
                  <div className='card-languages-left'>
                    <div className="inputs-row v-padding-z">
                      <div className="input-container-normal">
                        <div className='input-header-normal'>
                          Marché {index + 1}:
                        </div>
                        <div className='drawer-formfield-inactive'>
                          {skill.title}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className='card-languages-row-2col v-padding-z'>
                      <div className='card-languages-flex-fix'>
                        <div className="input-container-normal">
                          <div className='input-header-normal'>
                            Nom du marché :
                          </div>
                          <div className="card-languages-row">
                          <div className='card-languages-left'>
                            <Input
                              type="text"
                              name="title"
                              value={skill.title}
                              onChange={(e) => handleChange(index, e.target.name, e.target.value)}
                            />
                          </div>
                          
                          <div className='card-languages-right-icon-bare flex-fix'>
                              <IconButton
                                appearance="primary"
                                hasDropdownIndicator={false}
                                rounded={false}
                                className="align-right"
                                size="medium-default"
                                stateProp="normal"
                                type="default"
                                customIcon={mdiDeleteForeverOutline}
                                onClick={() => handleDelete(index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
          {isEditing && (
            <div className='card-languages-row'>
              <div className='card-languages-left'>
                <Button
                  appearance="secondary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label="Ajouter"
                  size="small"
                  stateProp="normal"
                  type="default"
                  customAfterIcon={mdiPlus}
                  onClick={handleAddExperience}
                  width='140px'
                />
              </div>
              <div className='card-languages-middle'>

              </div>
              <div className='card-languages-right'>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
