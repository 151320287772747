import "./style.css";
import React from "react";
import { mdiAccountOutline, mdiPlus, mdiBadgeAccountHorizontalOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '../../components/Button';
import api, { setupInterceptors } from '../../middlewares/api';
import { useAuth } from '../../middlewares/useAuth';
import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import EditUserForm from '../EditUserForm';
import MailtoLink from '../MailToLink';

export const CardPartnerStaff = ({ partner_name, partner_id, type }) => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);

  const [staff, setStaff] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);

  const triggerRefresh = () => {
    setRefresh(refresh + 1); // Increment refresh state to trigger data fetching
  };

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await api.get(`/admin/users/${partner_name}`);
        if (Array.isArray(response.data)) {
          const staffWithGroupsAndRolesAsString = response.data.map(user => ({
            ...user,
            groupsString: user.groups.map(group => group.name).join(', '),
            rolesString: user.roles.join(', ')
          }));
          setStaff(staffWithGroupsAndRolesAsString);
        } else {
          setStaff([]);
        }
      } catch (error) {
        console.error('Error fetching Applications:', error);
        setStaff([]); // Setting staff to an empty array on error
      }
    };
    fetchStaff();
  }, [partner_name, refresh]); // Add refresh as a dependency

  const openDrawer = (user) => {
    setSelectedUser(user);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedUser(null);
    setDrawerOpen(false);
  };

  const handleSave = () => {
    closeDrawer();
    triggerRefresh(); // Trigger a refresh after saving
  };

  return (
    <div className="card-component">
      <div className="card-header">
        <div>
          <Icon
            path={mdiBadgeAccountHorizontalOutline}
            color="#CED4DD"
            size="20px" />
          <h3 className="card-title">Collaborateurs</h3>
        </div>
        <div className="headr">
          <Button
            appearance="secondary"
            className="button-instance"
            hasAfterIcon={false}
            hasBeforeIcon={false}
            hasDropdownIndicator={false}
            intent="none"
            label="Ajouter un collaborateur"
            size="small"
            stateProp="normal"
            type="default"
            customBeforeIcon={mdiAccountOutline}
            customAfterIcon={mdiPlus}
            onClick={() => openDrawer(null)} // Open drawer with null to create a new user
          />
        </div>
      </div>
        <div className="card-maincontent">
          <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
            {staff.length === 0 ? (
              <p className="h-padding-s">Il n'y a encore aucun collaborateur</p>
            ) : (
              <Table sx={{ minWidth: 670, border: 'none' }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tabheader">
                    <TableCell align="left"><span className='list-table-header'>Nom</span></TableCell>
                    <TableCell align="left"><span className='list-table-header'>Email</span></TableCell>
                    <TableCell align="left"><span className='list-table-header'>Téléphone</span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staff.map((user) => (
                    <TableRow
                      key={user.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => openDrawer(user)} // Open drawer on row click
                      style={{ cursor: 'pointer' }} // Change cursor to pointer on hover
                    >
                      <TableCell align="left"><span className="tabcontent">{user.firstName} {user.lastName}</span></TableCell>
                      <TableCell align="left"><MailtoLink label={user.username} mailto={`mailto:${user.username}`} /></TableCell>
                      <TableCell align="left"><span className="tabcontent">{user?.attributes?.phone}</span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
      >
        <div>
          <EditUserForm user={selectedUser} closeDrawer={handleSave} onRefresh={triggerRefresh} partner_name={partner_name} partner_id={partner_id} type={type} />
        </div>
      </Drawer>
    </div>
  );
};
