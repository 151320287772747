import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CardRfpApplicationProposal } from '../mycomponents/CardRfpApplicationProposal';
import PartnerNameById from './PartnerNameById';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Input } from '../components/Input';
import { Status } from "../components/Status";
import { Button } from "../components/Button";
import { convertTimestampToDate } from '../utils/dateUtils';
import { getStatusColor, getStatusLabel } from '../utils/rfpStatusOptions';
import FileUploadHandler from '../mycomponents/FileUploadHandler';
import { CardSkills } from '../mycomponents/CardSkills';
import { CardApplicantWorkExperience } from './CardApplicantWorkExperience/CardApplicantWorkExperience';
import RfpRequirements from '../mycomponents/RfpRequirements';
import departements from '../utils/departements';
import { mdiFileCogOutline } from '@mdi/js';
import CandidateFinalValidationModal from './CandidateFinalValidationModal';
import CandidateRejectModal from './CandidateRejectModal';
import { useNavigate } from "react-router-dom";
import {
    applicantSkillLevelOptions
} from '../utils/StatusSkills';

const ApplicationDetails = ({ applicationId, onSave }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [rejectionModalOpen, setRejectionModalOpen] = useState(false);
    const navigate = useNavigate();
    const openModal = useCallback(() => setModalOpen(true), []);
    const closeModal = useCallback(() => setModalOpen(false), []);

    const openRejectionModal = useCallback(() => setRejectionModalOpen(true), []);
    const closeRejectionModal = useCallback(() => setRejectionModalOpen(false), []);


    const [contractDetails, setContractDetails] = useState({ partner_margin_rate: '0', client_margin_rate: '0', rate:'0' });

    const auth = useAuth();
    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();  // Ensure cleanup is called on component unmount
        }
    }, [auth.initialized, auth.updateToken]);

    const { userRoles } = useAuth();
    const isAdmin = userRoles.includes('admin');

    const [application, setApplication] = useState(null);
    const [editedApplication, setEditedApplication] = useState(null);
    const [editing, setEditing] = useState(false);
    const [applicantData, setApplicantData] = useState(null);
    const [defDep, setDefDep] = useState(null);
    const setRfpState = () => {

    }

    useEffect(() => {
        const fetchApplication = async () => {
            try {
                const response = await api.get(`/rfp/application/${applicationId}`);
                setApplication(response.data);
                setEditedApplication(response.data); // Initialize editedApplication with fetched data
                if (response.data.offer_details) {
                    setContractDetails(response.data.offer_details);
                }
                const responseApplicant = await api.get(`/applicant/${response.data.applicant_id}`);
                setApplicantData(responseApplicant.data);
                const match = departements.find(departement => departement.num_dep.toString() === responseApplicant.data.residence);
                setDefDep(match);
            } catch (error) {
                console.error('Error fetching application details:', error);
            }
        };

        fetchApplication();
    }, [applicationId]);

    const handleInputChange = useCallback((event) => {
        const { name, value } = event.target;
        setEditedApplication(prev => ({ ...prev, [name]: value }));
    }, []);

    if (!application) {
        return <p>Loading...</p>;
    }

    const onSaveContractDetails = (updatedDetails) => {
        setContractDetails(updatedDetails);
    };

    const updateStatus = async (status) => {
        setEditedApplication(prev => {
            if (prev.status !== status) {
                const updatedApplication = { ...prev, status };
                handleSave(updatedApplication);
                return updatedApplication;
            }
            return prev;
        });
    };

    const handleSaveSkills = () => {

    }

    const handleSave = async (applicationData) => {
        try {
            console.log("AppDetails:applicationData = ", applicationData)
            await api.put(`/rfp/application/${applicationId}`, {
                ...applicationData,
                offer_details: contractDetails, // Make sure to send back updated contract details if needed
            });
            setApplication(applicationData); // Update displayed application details
            setEditing(false);

            // Update contractDetails with application.offer_type or a similar field if available
            setContractDetails(applicationData.offer_details || { partner_margin_rate: '0', client_margin_rate: '0' });

            onSave();
        } catch (error) {
            console.error('Error saving application details:', error);
        }
    };
    const selectCandidate = () => {
        navigate(`/rc/${application.rfp_id}/${applicationId}`);
    }

    const updateApplicationAndRfpStatus = async (applicationId, rfpId, newStatus) => {
        try {
            // Log the input values to ensure they are correct
            console.log('Updating status with:', { applicationId, rfpId, newStatus });
            
            if (!applicationId || !rfpId || !newStatus) {
                throw new Error('Missing required parameters');
            }
    
            // Make the API request
            const response = await api.put('/rfp/application/updateStatus', {
                applicationId,  // This should be the correct application ID
                rfpId,          // This should be the correct RFP ID
                status: newStatus // This should be the new status
            });
    
            // Log the response for debugging
            console.log('Update response:', response.data);
            navigate(0);
            return response.data;
        } catch (error) {
            console.error('Error updating application and RFP status:', error.message || error);
            throw error;
        }
    };
    

    return (
        <div className='application-details drawer-container'>
            <CandidateFinalValidationModal
                open={modalOpen}
                handleClose={closeModal}
                handleValidation={() => updateApplicationAndRfpStatus(application.id, application.rfp_id, '4')}
            />
            <CandidateRejectModal
                open={rejectionModalOpen}
                handleClose={closeRejectionModal}
                handleReject={() => updateStatus('3')}
            />
            <div className="header drawer-title">
                <div className="rfptitle">
                    {application.trigraph}
                    <span className='h-padding-s'>
                        <Status
                            className="status-instance"
                            color={getStatusColor(application.status)}
                            emphasis="strong"
                            label={getStatusLabel(application.status)}
                            style={{ width: '100px' }}
                        />
                    </span>
                </div>
                {(isAdmin || userRoles.includes('client')) ? (
                    <div>
                        {parseInt(application.status) === 0 && (
                            <div className='card-header-buttons'>
                                <Button
                                    appearance="secondary"
                                    className="button-instance"
                                    hasAfterIcon={false}
                                    hasBeforeIcon={false}
                                    hasDropdownIndicator={false}
                                    intent="error"
                                    label="Rejeter"
                                    size="medium-default"
                                    stateProp="normal"
                                    type="default"
                                    onClick={() => updateStatus("2")}
                                />
                                <Button
                                    appearance="primary"
                                    className="button-instance"
                                    hasAfterIcon={true}
                                    hasBeforeIcon={true}
                                    hasDropdownIndicator={false}
                                    intent="positive"
                                    label="Retenir"
                                    size="medium-default"
                                    stateProp="normal"
                                    type="default"
                                    //onClick={() => updateStatus({ status: 1 })}
                                    onClick={selectCandidate}
                                />
                            </div>
                        )}
                        {parseInt(application.status) === 1 && (
                            <div className='card-header-buttons'>
                                <Button
                                    appearance="secondary"
                                    className="button-instance"
                                    hasAfterIcon={true}
                                    hasBeforeIcon={true}
                                    hasDropdownIndicator={false}
                                    intent="error"
                                    label="Rejeter"
                                    size="medium-default"
                                    stateProp="normal"
                                    type="default"
                                    onClick={openRejectionModal}
                                //onClick={() => updateStatus({ status: 3 })}
                                />
                                <Button
                                    appearance="primary"
                                    className="button-instance"
                                    hasAfterIcon={true}
                                    hasBeforeIcon={true}
                                    hasDropdownIndicator={false}
                                    intent="positive"
                                    label="Valider ce candidat défitivement"
                                    size="medium-default"
                                    stateProp="normal"
                                    type="default"
                                    //onClick={() => updateStatus({ status: 4 })}
                                    onClick={openModal}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <Button
                            appearance="secondary"
                            className="button-instance"
                            hasAfterIcon={true}
                            hasBeforeIcon={true}
                            hasDropdownIndicator={false}
                            intent="error"
                            label="Retirer"
                            size="medium-default"
                            stateProp="normal"
                            type="default"
                        />
                    </div>
                )}

            </div>
            <div>
                <Tabs>
                    <TabList>
                        <Tab><span className='padding-top-fix'>Infos</span></Tab>
                        <Tab>Compétences</Tab>
                        <Tab>Expériences</Tab>
                        <Tab>Comparatif</Tab>

                        {!userRoles.includes('client') && (
                            <Tab>Commissions</Tab>
                        )}
                    </TabList>
                    <TabPanel>
                        <div className='drawer-rfp-form-bloc'>
                            <div className='full-width'>
                                {application.resume && (
                                    <div className="input-container-normal">
                                        <div className='input-header-normal full-width'>
                                            <FileUploadHandler resumeData={application.resume} editingMode={false} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        Titre :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        {editing ? (
                                            <Input
                                                type="text"
                                                name="label"
                                                value={editedApplication.label}
                                                onChange={handleInputChange}
                                            />
                                        ) : application.label}
                                    </div>
                                </div>
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        Partnenaire :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        <PartnerNameById id={application.partner_id} type="partner" />
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        Prénom :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        {editing ? (
                                            <Input
                                                type="text"
                                                name="first_name"

                                                value={editedApplication.first_name}
                                                onChange={handleInputChange}
                                            />
                                        ) : application.first_name}
                                    </div>
                                </div>
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        Nom :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        {editing ? (
                                            <Input
                                                type="text"
                                                name="last_name"

                                                value={editedApplication.last_name}
                                                onChange={handleInputChange}
                                            />
                                        ) : application.last_name}
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        Date de naissance :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        {editing ? (
                                            <Input
                                                type="text"
                                                name="birth_date"

                                                value={convertTimestampToDate(editedApplication.birth_date)}
                                                onChange={handleInputChange}
                                            />
                                        ) : convertTimestampToDate(editedApplication.birth_date)}
                                    </div>
                                </div>
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        Lieu de naissance :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        {editing ? (
                                            <Input
                                                type="text"
                                                name="birth_place"
                                                value={editedApplication.birth_place}
                                                onChange={handleInputChange}
                                            />
                                        ) : application.birth_place}
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        Département de résidence :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        {editing ? (
                                            <Input
                                                type="text"
                                                name="residence"

                                                value={editedApplication.residence}
                                                onChange={handleInputChange}
                                            />
                                        ) : `${defDep?.dep_name} (${editedApplication.residence})`}
                                    </div>
                                </div>
                                <div className="input-container-normal">
                                    <div className='input-header-normal'>
                                        TJM :
                                    </div>
                                    <div className='drawer-formfield-inactive'>
                                        {editing ? (
                                            <Input
                                                type="text"
                                                name="rate"

                                                value={editedApplication.rate}
                                                onChange={handleInputChange}
                                            />
                                        ) : application.rate + "€ HT/jour"}
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                {application.offer_details.comment && (
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            Commentaire partenaire :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {application.offer_details.comment}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='drawer-container'>
                            {applicantData ? (
                                <CardSkills
                                    skills={applicantData.skills}
                                    iconPath={mdiFileCogOutline}
                                    saveLanguages={handleSaveSkills}
                                    title="Compétences"
                                    placeHolder="Nouvelle compétence"
                                    editButton={false}
                                    enabled={false}
                                    context='application'
                                    SkillLevelOptions={applicantSkillLevelOptions}
                                />
                            ) : (
                                <p className='header-xsmall'>Chargement...</p>
                            )}
                        </div>
                        <div className='drawer-container'>
                            {applicantData ? (
                                <CardSkills
                                    skills={applicantData.diplomas}
                                    iconPath={mdiFileCogOutline}
                                    saveLanguages={handleSaveSkills}
                                    title="Diplômes et certifications"
                                    placeHolder="Nouvelle certification"
                                    editButton={false}
                                    enabled={false}
                                    context='application'
                                    hasLevelSelect={false}
                                    SkillLevelOptions={applicantSkillLevelOptions}
                                />
                            ) : (
                                <p className='header-xsmall'>Chargement...</p>
                            )}
                        </div>
                        <div className='drawer-container'>
                            {applicantData ? (
                                <CardSkills
                                    skills={applicantData.languages}
                                    iconPath={mdiFileCogOutline}
                                    saveLanguages={handleSaveSkills}
                                    title="Langues"
                                    placeHolder="Nouvelle langue"
                                    editButton={false}
                                    enabled={false}
                                    context='application'
                                    hasLevelSelect={true}
                                    SkillLevelOptions={applicantSkillLevelOptions}
                                />
                            ) : (
                                <p className='header-xsmall'>Chargement...</p>
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='drawer-container'>
                            {applicantData ? (
                                <CardApplicantWorkExperience
                                    experiences={applicantData.workexperiences}
                                    title="Expériences professionnelles"
                                    placeHolder="Nouvelle compétence"
                                    editButton={false}
                                    enabled={false}
                                    context='application'
                                    SkillLevelOptions={applicantSkillLevelOptions}
                                />
                            ) : (
                                <p className='header-xsmall'>Chargement...</p>
                            )}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='drawer-container'>
                            {applicantData ? (
                                <RfpRequirements state={application.assessment} onStateChange={setRfpState} context='proposal' />

                            ) : (
                                <p className='header-xsmall'>Chargement...</p>
                            )}
                        </div>

                    </TabPanel>
                    {!userRoles.includes('client') && (
                        <TabPanel>
                            <CardRfpApplicationProposal
                                rate={application.rate}
                                contractDetails={contractDetails}
                                onSaveContractDetails={onSaveContractDetails}
                                editing={true}
                                isAdmin={isAdmin}
                            />
                        </TabPanel>
                    )}

                </Tabs>
            </div>
        </div>
    );
};

export default ApplicationDetails;
