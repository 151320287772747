/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const MdiCheckCircleOutline = ({ color = "white", className }) => {
  return (
    <svg
      className={`mdi-check-circle-outline ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10 1.66666C5.41666 1.66666 1.66666 5.41666 1.66666 9.99999C1.66666 14.5833 5.41666 18.3333 10 18.3333C14.5833 18.3333 18.3333 14.5833 18.3333 9.99999C18.3333 5.41666 14.5833 1.66666 10 1.66666ZM10 16.6667C6.325 16.6667 3.33333 13.675 3.33333 9.99999C3.33333 6.32499 6.325 3.33332 10 3.33332C13.675 3.33332 16.6667 6.32499 16.6667 9.99999C16.6667 13.675 13.675 16.6667 10 16.6667ZM13.825 6.31666L8.33333 11.8083L6.175 9.65832L5 10.8333L8.33333 14.1667L15 7.49999L13.825 6.31666Z"
        fill={color}
      />
    </svg>
  );
};

MdiCheckCircleOutline.propTypes = {
  color: PropTypes.string,
};
