import React, { useState, useRef, useEffect } from 'react';
import './style.css';
import Icon from '@mdi/react';
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fr } from 'date-fns/locale';
import { setDay } from 'date-fns';
import { convertTimestampToDate } from '../../utils/dateUtils';

export const Input = ({ iconBefore: IconBefore, iconAfter: IconAfter, placeholder, suffix, customWidth="100%", type="text", onChange, value, name, required, isInvalid=false, openToCurrentDate = false,
                        max
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [suffixWidth, setSuffixWidth] = useState(0);
  const inputRef = useRef(null);
  const suffixRef = useRef(null);
  const datePickerRef = useRef(null);  // Define a ref for DatePicker
  const [date, setDate] = useState(() => {
    if (value) {
      return new Date(value);  // Use the existing date value if provided
    } 
    return openToCurrentDate ? new Date() : setDay(new Date(), 1);  // Open to current date if specified, otherwise set default
  });
  
  useEffect(() => {
    if (suffixRef.current) {
      setSuffixWidth(suffixRef.current.getBoundingClientRect().width + 18); // +10 pour un peu plus d'espace
    }
  }, [suffix]);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleIconClick = () => {
    if (type === "date" && datePickerRef.current) {
      datePickerRef.current.setFocus();  // Programmatically focus the DatePicker
    } else if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  let paddingClass = '';
  if (IconBefore && IconAfter) {
    paddingClass = 'with-icons';
  } else if (IconBefore) {
    paddingClass = 'with-icon-before';
  } else if (IconAfter) {
    paddingClass = 'with-icon-after';
  } else {
    paddingClass = 'without-icons';
  }

  const handleDateChange = (newDate) => {
   
    setDate(newDate); // Update local state
    if (onChange) {
      const event = {
        target: {
          name: name, // Replace "startDate" with the appropriate field name
          value: newDate,
        }
      };
      //console.log("onChange:", event);
      onChange(event); 
    }
  };

  return (
    <div className={`input-wrapper ${isFocused ? 'focused' : ''}`}>
      {IconBefore && <Icon path={IconBefore} className="icon before" onClick={handleIconClick} />}
      <div className={`input-container  ${paddingClass} ${isInvalid ? 'input-error' : ''}`}>
        {type !== 'date' ? (
          <input
            ref={inputRef}
            type={showPassword ? 'text' : type}
            placeholder={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={onChange}
            style={{ width: customWidth, paddingRight: suffix ? `${suffixWidth}px` : '12px' }} // paddingRight dynamique
            className="input"
            value={value}
            name={name}
            required={required}
            max={type === 'number' ? max : undefined}
          />
        ) : (
          <DatePicker
            selected={date}
            ref={datePickerRef}  // Attach the ref to DatePicker
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            placeholderText={placeholder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{ width: customWidth }}
            className="input"
            locale={fr}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"  
            startDate={setDay(new Date(), 1)}
            value={value? convertTimestampToDate(value):''}
            name={name}
            openToDate={openToCurrentDate ? new Date() : undefined}  // Conditional logic

          />
        )}
        {suffix && <span ref={suffixRef} className="suffix">{suffix}</span>}
      </div>
      {IconAfter && (
        type !== 'password' ? (
          <Icon path={IconAfter} className="icon after" onClick={handleIconClick} />
        ) : (
          <Icon path={showPassword ? mdiEyeOutline : mdiEyeOffOutline} className="icon after" onClick={togglePasswordVisibility} />
        )
      )}
    </div>
  );
};