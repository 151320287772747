import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { LogoEquity1 } from "../../icons/LogoEquity1";
import {
  mdiBullhornOutline,
  mdiDomain,
  mdiAccountSupervisorCircleOutline,
  mdiCardAccountDetailsOutline,
} from '@mdi/js';
import SideNavItem  from "../SideNavItem/SideNavItem";
import { useLocation } from 'react-router-dom'; 
import "./style.css";

export const SideNav = ({ isCollapse, className, userRoles }) => {
  const [state] = useReducer(reducer, {
    isCollapse: isCollapse || false,
  });
  const location = useLocation(); // Get the current location object

  var items = [];

  
  if (userRoles.includes('admin')) {
  items = [
    { label: "Appel d'offres", id: 'rfp', customIcon: mdiBullhornOutline, path:"/rfp" },
    { label: "Missions", id: 'missions', customIcon: mdiCardAccountDetailsOutline, path:"/missions"},
    { label: "Clients", id: 'clients', customIcon: mdiDomain, path:"/clients"},
    { label: "Partenaires", id: 'partners', customIcon: mdiAccountSupervisorCircleOutline, path:"/partners"},
    { label: "Candidats", id: 'applicants', customIcon: mdiCardAccountDetailsOutline, path:"/applicants"},
    // Add more items as needed
  ];
} else if (userRoles.includes('partner') || userRoles.includes('Partnenaire')) {
  items = [
    { label: "Appel d'offres", id: 'rfp', customIcon: mdiBullhornOutline, path:"/rfp" },
    { label: "Mes candidatures", id: 'myapplications', customIcon: mdiCardAccountDetailsOutline, path:"/myapplications"},
    { label: "Candidats", id: 'applicants', customIcon: mdiCardAccountDetailsOutline, path:"/applicants"},
    { label: "Ma société", id: 'company', customIcon: mdiCardAccountDetailsOutline, path:"/company"},
    // Add more items as needed
  ];
} else if (userRoles.includes('client')|| userRoles.includes('Client')) {
  items = [
    { label: "Appel d'offres", id: 'rfp', customIcon: mdiBullhornOutline, path:"/rfp" },
    // Add more items as needed
  ];
}

  return (
    <div className={`side-nav is-collapse-${state.isCollapse} ${className}`}>
      <div className="nav">
        <div className="side-nav-header">
          <div className="logo">
            <a href='/'><LogoEquity1 className="logo-equity" /></a>
          </div>
        </div>
        {items.map((item) => (
          <SideNavItem
            key={item.id}
            className={item.className ? item.className : ""}
            counter={item.counter ? item.counter : false}
            customIcon={item.customIcon}
            isCollapse={state.isCollapse}
            stateProp={"default"}
            label={item.label}
            path={item.path}
            active={location.pathname.includes(item.path)}
          />
        ))}
      </div>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "toggle_collapse":
      return { ...state, isCollapse: !state.isCollapse };
    default:
      return state;
  }
}

SideNav.propTypes = {
  isCollapse: PropTypes.bool,
};
