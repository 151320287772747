import React, { useState } from "react";
import { mdiDomain } from '@mdi/js';
import CardHeader from '../CardHeader';
import "./style.css";

export const CardCheckList = ({
  tasks, 
  onTaskChange,
  editButton
}) => {

  const [isEditing, setIsEditing] = useState(false);

  const handleCheckboxChange = (index) => {
    const updatedTasks = tasks.map((task, i) =>
      i === index ? { ...task, completed: !task.completed } : task
    );
    onTaskChange(updatedTasks);
  };

  return (
    <div className="card-component">
      <CardHeader
        isEditing={!isEditing}
        context='editor'
        editButton={editButton}
        iconPath={mdiDomain}
        title='Préparation mission'
      />
      <div className="card-content card-content-v-padding">
        <div className="list-rfp-content">
          Voici une liste des choses à faire avant de démarrer la mission :
        </div>
        <ul className="full-width">
          {tasks.map((task, index) => (
            <li key={index} className={`tasks ${task.completed ? 'task-completed' : ''}`} >
              <div className="task-item">
                <input
                  type="checkbox"
                  checked={task.completed}
                  onChange={() => handleCheckboxChange(index)}
                />
                <span className="label">{task.label}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
