import React, { useState, useEffect, useRef } from "react";
import { mdiDomain } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { Input } from "../../components/Input";
import CardHeader from '../CardHeader';
import "./style.css";

export const CardMissionInfo = ({
  client_name = "client_name",
  rfp_title,
  market,
  client_id,
  amount,
  offerType,
  client_ref,
  eq_ref,
  handleSubmit,
  onMissionInfoChange,
  editButton
}) => {

  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [missionInfo, setMissionInfo] = useState({
    rfp_title: rfp_title,
    market: market,
    amount: amount,
    offerType: offerType,
    client_ref: client_ref,
    eq_ref: eq_ref,
  });

  const initialMissionInfoRef = useRef({
    rfp_title: rfp_title,
    market: market,
    amount: amount,
    offerType: offerType,
    client_ref: client_ref,
    eq_ref: eq_ref,
  });

  useEffect(() => {
    setMissionInfo({
      rfp_title: rfp_title,
      market: market,
      amount: amount,
      offerType: offerType,
      client_ref: client_ref,
      eq_ref: eq_ref,
    });
  }, [rfp_title, market, amount, offerType, client_ref, eq_ref]);

  // Update local state and call the parent callback on change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newMissionInfo = { ...missionInfo, [name]: value };
    setMissionInfo(newMissionInfo);
    if (onMissionInfoChange) {
      onMissionInfoChange(newMissionInfo);
    }
  };

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();
    }
    setIsEditing(!isEditing);
  };

  const handleClick = () => {
    navigate('/clients/' + client_id);
  };

  const handleDiscard = () => {
    setMissionInfo(initialMissionInfoRef.current);
    if (onMissionInfoChange) {
      onMissionInfoChange(initialMissionInfoRef.current);
    }
    setIsEditing(!isEditing);
  };
  return (
    <div className="card-component">
      <CardHeader
        isEditing={!isEditing}
        context='editor'
        editButton={editButton}
        iconPath={mdiDomain}
        title='Mission'
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
      <div className="card-content card-content-v-padding">
        <div className="card-languages-list">
          <div className="card-languages-list">
            <div className="card-label">
              Nom de la mission :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="rfp_title"
                  value={missionInfo.rfp_title}
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : missionInfo.rfp_title}
            </div>
          </div>
        </div>
        <div className='card-row-std'>
          <div className='card-languages-left'>
            <div className="card-label">
              Nom du client :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="client_name"
                  value={client_name}
                  onChange={handleInputChange}
                  className="input-normal"
                  disabled
                />
              ) : (<span className="underlined" onClick={handleClick}>{client_name}</span>)}
            </div>
          </div>
          <div>
            {(market||isEditing) && (
              <div>
                <div className="card-label">
                  Nom du marché :
                </div>
                <div className="card-maincontent">
                  {isEditing ? (
                    <Input type="text"
                      name="market"
                      value={missionInfo.market}
                      onChange={handleInputChange}
                      className="input-normal"
                    />
                  ) : missionInfo.market}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='card-row-std'>
          <div className='card-languages-left'>
            <div className="card-label">
              Ref. Equity :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="eq_ref"
                  value={missionInfo.eq_ref}
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : missionInfo.eq_ref}
            </div>
          </div>
          <div>
            <div className="card-label">
              Ref. Client :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="client_ref"
                  value={missionInfo.client_ref}
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : missionInfo.client_ref}
            </div>
          </div>
        </div>
        <div className='card-row-std'>
          <div className='card-languages-left'>
            <div className="card-label">
              Typologie de prestation :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="offerType"
                  value={missionInfo.offerType}
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : missionInfo.offerType}
            </div>
          </div>
          <div>
            <div className="card-label">
              Montant estimé :
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input type="text"
                  name="amount"
                  value={missionInfo.amount}
                  onChange={handleInputChange}
                  className="input-normal"
                  suffix="€ HT"
                />
              ) : `${missionInfo.amount} € HT`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
