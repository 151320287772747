import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Input } from '../components/Input';
import { Tag } from '../components/Tag';
import { convertTimestampToDate } from '../utils/dateUtils';
import FilterModal from './FilterModal';
import { Button } from '../components/Button';
import { mdiFilterVariant, mdiMagnify, mdiMenuSwap } from '@mdi/js';
import Icon from '@mdi/react';
import TablePaginationActions from './TablePaginationActions';
import PartnerNameById from './PartnerNameById';
import { Status } from "../components/Status";

export default function MissionsList() {
    const navigate = useNavigate();
    
    // State Variables
    const [missions, setMissions] = useState([]);
    const [clients, setClients] = useState([]);
    const [partners, setPartners] = useState([]);
    const [sortField, setSortField] = useState('Date_debut_CA');
    const [sortDirection, setSortDirection] = useState('desc');
    const [filterText, setFilterText] = useState('');
    const [filters, setFilters] = useState({
        client: '',
        partner: '',
        status: '',
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const auth = useAuth();

    // Setup API Interceptors
    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    // Status Options
    const statusOptions = useMemo(() => [
        { value: 0, color: "neutral", label: "A venir" },
        { value: 1, color: "positive", label: "En cours" },
        { value: 2, color: "warning", label: "Terminée" },
        { value: 3, color: "negative", label: "Fin Anticipée" },
    ], []);

    // Fetch Missions Data
    useEffect(() => {
        const fetchMissions = async () => {
            try {
                const response = await api.get(`/mission`);
                setMissions(response.data.items);

                // Extract unique clients and partners
                const uniqueClients = [
                    ...new Set(response.data.items.map(item => item.client_name))
                ].map(client => ({ id: client, name: client }));

                const uniquePartners = [
                    ...new Set(response.data.items.map(item => item.partner_id))
                ].map(partnerId => ({ id: partnerId, name: `Partner ${partnerId}` })); // Assuming partner names can be derived or fetched

                setClients(uniqueClients);
                setPartners(uniquePartners);
            } catch (error) {
                console.error('Error fetching missions:', error);
            }
        };
        fetchMissions();
    }, []);

    // Handle Filter Changes
    const handleFilterChange = useCallback((name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);

    // Reset All Filters
    const resetFilters = useCallback(() => {
        setFilters({
            client: '',
            partner: '',
            status: '',
        });
    }, []);

    // Close a Specific Filter Tag
    const closeTag = (key) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: ''
        }));
    };

    // Filter and Sort Missions
    const filteredAndSortedMissions = useMemo(() => {
        return missions
            .filter(row => {
                // Text Search Filter
                const rowString = [
                    row.client_name,
                    row.Consultant,
                    row.Partenaires,
                    row.mission_title,
                    row.Bon_de_commande,
                    row.status,
                ].join(' ').toLowerCase();

                const matchesTextFilter = rowString.includes(filterText.toLowerCase());

                // Client Filter
                const matchesClientFilter = !filters.client || row.client_name === filters.client;

                // Partner Filter
                const matchesPartnerFilter = !filters.partner || row.partner_id === filters.partner;

                // Status Filter
                const matchesStatusFilter = !filters.status || row.status === parseInt(filters.status, 10);

                return matchesTextFilter && matchesClientFilter && matchesPartnerFilter && matchesStatusFilter;
            })
            .sort((a, b) => {
                if (sortField && a[sortField] && b[sortField]) {
                    if (sortDirection === 'asc') {
                        return a[sortField].toString().localeCompare(b[sortField].toString(), undefined, { numeric: true });
                    } else {
                        return b[sortField].toString().localeCompare(a[sortField].toString(), undefined, { numeric: true });
                    }
                }
                return 0;
            });
    }, [missions, filterText, filters, sortField, sortDirection]);

    // Pagination
    const paginatedMissions = useMemo(() => {
        return filteredAndSortedMissions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [filteredAndSortedMissions, page, rowsPerPage]);

    // Get Status Color
    const getStatusColor = useCallback((status) => {
        const option = statusOptions.find(option => option.value === status);
        if (!option) {
            console.error(`No matching status option found for: ${status}`);
            return 'neutral';
        }
        return option.color;
    }, [statusOptions]);

    // Get Status Label
    const getStatusLabel = useCallback((status) => {
        const option = statusOptions.find(option => option.value === status);
        return option ? option.label : 'Unknown';
    }, [statusOptions]);

    // Open and Close Modal
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    // Handle Page Change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle Rows Per Page Change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Define Filter Options
    const filterOptions = [
        {
            key: 'status',
            label: 'Status',
            defaultLabel: 'Tous les statuts',
            options: statusOptions.map(option => ({ value: option.value.toString(), label: option.label }))
        },
        {
            key: 'client',
            label: 'Client',
            defaultLabel: 'Tous les clients',
            options: clients.map(client => ({ value: client.id, label: client.name }))
        },
        {
            key: 'partner',
            label: 'Partenaire',
            defaultLabel: 'Tous les partenaires',
            options: partners.map(partner => ({ value: partner.id, label: partner.name }))
        },
    ];

    // Custom Styles (if any)
    const headerStyles = {};

    return (
        <div>
            {/* Filter Modal */}
            <FilterModal
                open={modalOpen}
                handleClose={closeModal}
                filters={filters}
                filterOptions={filterOptions}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
            />

            {/* Table Container */}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="missions table">
                    <TableHead>
                        {/* Search and Active Filters Row */}
                        <TableRow>
                            {/* Search Input */}
                            <TableCell align="left" colSpan={3}>
                                <Input
                                    type="text"
                                    placeholder="Rechercher"
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                    customWidth="160px"
                                    iconBefore={mdiMagnify}
                                />
                            </TableCell>

                            {/* Active Filter Tags */}
                            <TableCell align="left" colSpan={7}>
                                {filterOptions.map((filterOption) => {
                                    let optionLabel = '';

                                    if (filterOption.key === 'status') {
                                        const option = statusOptions.find(option => option.value.toString() === filters[filterOption.key]);
                                        optionLabel = option ? option.label : filters[filterOption.key];
                                    } else if (filterOption.key === 'client') {
                                        const client = clients.find(client => client.id === filters[filterOption.key]);
                                        optionLabel = client ? client.name : filters[filterOption.key];
                                    } else if (filterOption.key === 'partner') {
                                        const partner = partners.find(partner => partner.id === filters[filterOption.key]);
                                        optionLabel = partner ? partner.name : filters[filterOption.key];
                                    }

                                    return filters[filterOption.key] ? (
                                        <Tag
                                            key={filterOption.key}
                                            className="tag-instance"
                                            hasCloseButton={true}
                                            label={optionLabel}
                                            prefix={filterOption.label}
                                            states="default"
                                            onClickClose={() => closeTag(filterOption.key)}
                                        />
                                    ) : null;
                                })}
                            </TableCell>

                            {/* Filter Button */}
                            <TableCell align='right'>
                                <Button
                                    appearance="secondary"
                                    className="button-instance"
                                    hasAfterIcon={false}
                                    hasBeforeIcon={true}
                                    hasDropdownIndicator={false}
                                    intent="none"
                                    label="Filtrer"
                                    size="small"
                                    stateProp="normal"
                                    type="default"
                                    customBeforeIcon={mdiFilterVariant}
                                    onClick={openModal}
                                />
                            </TableCell>
                        </TableRow>

                        {/* Table Headers */}
                        <TableRow sx={{ ...headerStyles }}>
                            {/* Sort Toggle */}
                            <TableCell
                                sx={{
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    paddingLeft: '8px',
                                    paddingRight: '0px',
                                    border: 0,
                                    width: 10
                                }}
                            >
                                <Icon
                                    path={mdiMenuSwap}
                                    size={1}
                                    onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
                                    style={{ cursor: 'pointer' }}
                                />
                            </TableCell>

                            {/* Column Headers with Sort */}
                            <TableCell align="left"><span className='list-table-header'>Ref Equity</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Intitulé</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Client</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Ref Client</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Statut</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Partenaires</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Consultant</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>Bon de commande</span></TableCell>
                            <TableCell align="left" onClick={() => setSortField('Date_debut_CA')} style={{ cursor: 'pointer' }}>
                                <span className='list-table-header'>Date début</span>
                            </TableCell>
                            <TableCell align="left" onClick={() => setSortField('Date_fin_CA')} style={{ cursor: 'pointer' }}>
                                <span className='list-table-header'>Date fin</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                        {paginatedMissions.map((row) => (
                            <TableRow
                                key={row.id}
                                hover={true}
                                onClick={() => navigate(`/missions/${row.id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ cursor: 'pointer' }}
                            >
                                {/* Empty Cell for Sort Icon */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    {/* Potentially an icon or empty */}
                                </TableCell>

                                {/* Ref Equity */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-eq_ref'>{row.eq_ref}</span>
                                </TableCell>

                                {/* Intitulé */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-title'>{row.mission_title}</span>
                                </TableCell>

                                {/* Client */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <div className='cell-text-label'>{row.client_name}</div>
                                </TableCell>

                                {/* Ref Client */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-eq_ref'>{row.client_ref}</span>
                                </TableCell>

                                {/* Statut */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <Status
                                        color={getStatusColor(row.status)}
                                        label={getStatusLabel(row.status)}
                                        emphasis="strong"
                                    />
                                </TableCell>

                                {/* Partenaires */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-content'>
                                        <PartnerNameById id={row.partner_id} type="partner" />
                                    </span>
                                </TableCell>

                                {/* Consultant */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-content'>{row.applicant_name}</span>
                                </TableCell>

                                {/* Bon de commande */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-content'>{row.Bon_de_commande}</span>
                                </TableCell>

                                {/* Date début */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-content'>{convertTimestampToDate(row.start_date)}</span>
                                </TableCell>

                                {/* Date fin */}
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-content'>{convertTimestampToDate(row.end_date)}</span>
                                </TableCell>
                            </TableRow>
                        ))}

                        {/* Display a message if no missions match the filters */}
                        {paginatedMissions.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={11} align="center">
                                    Aucune mission trouvée.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>

                {/* Pagination */}
                <TablePagination
                    className="custom-pagination"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredAndSortedMissions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Lignes par page :"
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count, page }) => {
                        const totalPages = Math.ceil(count / rowsPerPage);
                        return `${page + 1} / ${totalPages}`;
                    }}
                />
            </TableContainer>
        </div>
    );
}
