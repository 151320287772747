// EditUserForm.js
import React, { useState, useEffect } from 'react';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Input } from '../components/Input';
import { Button } from "../components/Button";
import { mdiAccountOutline, mdiPlus, mdiClose } from '@mdi/js';
import { IconButton } from '../components/IconButton';

const EditUserForm = ({ closeDrawer, user = {}, onRefresh, type, partner_id, partner_name }) => {
  const [formData, setFormData] = useState({
    username: user?.username || '',
    partner_id: user?.attributes?.partner_id || partner_id,
    partner_name: user?.groupsString || partner_name,
    maincontact: user?.maincontact || false,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phone: user?.attributes?.phone || '',
    roles: user?.rolesString || '',
    type: type|| '',
  });

  const auth = useAuth();

  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken, user]);

  const [invalidFields, setInvalidFields] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newInvalidFields = { ...invalidFields };

    if ((typeof value === 'string' && value.trim() === '') || (typeof value !== 'string' && value.length === 0)) {
      newInvalidFields[name] = true; // mark as invalid if empty
    } else {
      delete newInvalidFields[name]; // remove from invalid fields if it's now valid
    }

    setInvalidFields(newInvalidFields);
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const validateFields = () => {
    const requiredFields = ['username', 'partner_id', 'partner_name'];
    const newInvalidFields = {};
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newInvalidFields[field] = true;
      }
    });
    setInvalidFields(newInvalidFields);
    return Object.keys(newInvalidFields).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      console.error("Invalid input, cannot submit.", invalidFields);
      return;
    }

    try {
      console.log('Submitting form:', formData);
      if (user?.id) {
        // Update existing user
        await api.put(`/admin/updateUser/${user.id}`, formData);
      } else {
        // Create new user
        await api.post('/admin/createUser', formData);
      }
      if (onRefresh) onRefresh(); // Trigger refresh in parent
      closeDrawer(); // Close the Drawer after successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  return (
    <div className='drawer-container'>
      <form onSubmit={handleSubmit}>
        <div className="header drawer-title">
          <div className="header rfptitle">Create/Edit User</div>
          <div className="header">
            <Button
              appearance="primary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              label="Enregistrer"
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
              width='140px'
              onClick={handleSubmit}
            />
            <IconButton
              appearance="primary"
              rounded={false}
              size="small"
              type="ghost"
              customIcon={mdiClose}
              onClick={closeDrawer} // Close the Drawer on button click
            />
          </div>
        </div>
        <div className='drawer-rfp-form-bloc'>
        <div className='header-xsmall'>
            Informations
          </div>
          <div className="inputs-row">
          <div className='input-container-normal'>
            <div className='input-header-normal'>
                Nom :
              </div>
              <div className='input-content-normal'>
                <Input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  isInvalid={invalidFields.lastName}
                  required
                />
              </div>
            </div>
            <div className='input-container-normal'>
            <div className='input-header-normal'>
                Prénom :
              </div>
              <div className='input-content-normal'>
                <Input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  isInvalid={invalidFields.firstName}
                  required
                />
              </div>
            </div>
            </div>
            <div className="inputs-row">
            <div className='input-container-normal'>
            <div className='input-header-normal'>
                Société :
              </div>
              <div className='input-content'>
                <Input
                  type="text"
                  name="partner_name"
                  value={formData.partner_name}
                  onChange={handleInputChange}
                  isInvalid={invalidFields.partner_name}
                  required
                />
                <input type="hidden" id="partner_id" name="partner_id" value={formData.partner_id} />
              </div>
            </div>
            <div className='input-container-normal'>
            <div className='input-header-normal'>
                Rôle :
              </div>
              <div className='input-content'>
                <Input
                  type="text"
                  name="roles"
                  value={formData.roles}
                  onChange={handleInputChange}
                  disabled
                />
              </div>
            </div>
            </div>
          <div className="inputs-row">
          <div className='input-container-normal'>
            <div className='input-header-normal'>
                email :
              </div>
              <div className='input-content-normal'>
                <Input
                  type="email"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  isInvalid={invalidFields.username}
                  required
                />
              </div>
            </div>
            <div className='input-container-normal'>
            <div className='input-header-normal'>
                Téléphone :
              </div>
              <div className='input-content'>
                <Input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                 
                  
                />
              </div>
            </div>
            </div>

            <div className="inputs-row">
            <div className='input-container-normal'></div>
            <div className='input-container-normal'>
            <div className='input-header-normal'>
                Contact Principal :
              </div>
              <div className='input-content'>
                <input
                  type="checkbox"
                  name="maincontact"
                  checked={formData.maincontact}
                  onChange={handleCheckboxChange}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditUserForm;
