import React from "react";
import "./style.css";

import { mdiPower } from '@mdi/js';
import { Button } from "../Button";
 

export const SideNavItemUserInfo = ({ userRoles = "test", name, acronym, onClick }) => {

    return (
        <div className="side-nav-item-user-info-container">
            <div className="side-nav-item-user-info">
                <div className="user-info-acronym">
                    <div className="side-nav-item-user-info-acronym">{acronym}</div>
                </div>
                <div className="side-nav-item-user-info-user-info-details">
                    <div className="side-nav-item-user-info-user-name">{name}</div>
                    <div className="side-nav-item-user-info-user-role">{userRoles}</div>
                </div>
            </div>
            <div className="side-nav-item-user-info-logout-button">
                <Button label="Se déconnecter"
                    appearance="secondary"
                    className="button-instance"
                    hasAfterIcon={false}
                    hasBeforeIcon={true}
                    hasDropdownIndicator={false}
                    intent="none"
                    size="medium-default"
                    stateProp="normal"
                    type="ghost"
                    customBeforeIcon={mdiPower}
                    customAfterIcon={mdiPower}
                    onClick={onClick}
                />
            </div>

        </div>
    );
};
